import React, { useState, useEffect } from "react";

import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import { FiDownload, FiAward, FiUpload } from "react-icons/fi";

import { coin } from "../../assets/images/index.js";

import Logo from '../../assets/images/logo.png';
import token_busd from '../../assets/images/busd.png';
import bg_border from '../../assets/images/backgrounds/bg-video-old-2.svg';


const Wallet = () => {

    const balanceBNB = 0;
    const balanceBUSD = 0;
    const balanceMZB = 0;

    const [showModal, setShowModal] = useState(false);
    const [showModalWithdrawBusd, setShowModalWithdrawBusd] = useState(false);

    const [balanceRewardFST, setBalanceRewardFST] = useState(0);
    const [balanceRewardBUSD, setBalanceRewardBUSD] = useState(0);

    const [amountWithdrawRewardFST, setAmountWithdrawRewardFST] = useState(0);
    const [amountWithdrawRewardBUSD, setAmountWithdrawRewardBUSD] = useState(0);

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="mtz-profile_title text-white text-left mt-0">
                            Wallet
                        </div>
                        <hr />
                    </div>
                    <div className="col-lg-6 col-12">
                        <div className="wallet-box">
                            <span className="text-white">Total Balance</span>
                            <div className="money-wrap total-wrap d-grid">
                                <div className="money">
                                    <h2 className="text-primary">{balanceBNB.toFixed(3)} BNB</h2>
                                </div>
                                <div className="money">
                                    <h2 className="text-primary">{balanceBUSD.toFixed(3)} BUSD</h2>
                                </div>
                                <div className="money">
                                    <h2 className="text-primary">{balanceMZB.toFixed(3)} MZB</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12">
                        <div className="wallet-box">
                            <div className="mtz-icon-wrap">
                                <img src={coin} alt="" />
                            </div>
                            <span className="text-white">Price Of MZB</span>
                            <div className="money-wrap">
                                <div className="money">
                                    <h2 className="text-primary">BNB : 0.00000</h2>
                                    {/* <span className="text-white d-block">BUSD PRICE : $0</span> */}
                                </div>
                            </div>
                            <div className="btn-import-wrap d-flex">
                                <button className="btn rotated-btn">
                                    <div className="fs-btn-wrap">
                                        <FiDownload /> Import BUSD
                                    </div>
                                </button>
                                <button className="btn rotated-btn">
                                    <div className="fs-btn-wrap">
                                        <FiDownload /> Import MZB
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt-5">
                        <div className="mtz-profile_title text-white text-left mt-0">
                            Reward in game
                        </div>
                        <hr />
                    </div>
                    <div className="col-lg-6 col-12">
                        <div className="wallet-box">
                            <div className="mtz-icon-wrap">
                                <img src={token_busd} alt="" />
                            </div>
                            <span className="text-white">BUSD (BEP20)</span>
                            <div className="money-wrap d-grid">
                                <div className="money">
                                    <h2 className="text-primary mb-2">{balanceRewardFST} MZB</h2>
                                </div>
                                <div className="money">
                                    <span className="text-primary d-block">{balanceRewardBUSD} BUSD</span>
                                </div>
                            </div>
                            <button className="btn rotated-btn">
                                <div className="fs-btn-wrap">
                                    <FiUpload /> Withdraw USD
                                </div>
                            </button>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12">
                        <div className="wallet-box">
                            <div className="mtz-icon-wrap">
                                <img src={coin} alt="" />
                            </div>
                            <span className="text-white">MetaZombies ($MZB)</span>
                            <div className="money-wrap d-grid">
                                <div className="money">
                                    <h2 className="text-primary mb-2">{balanceRewardFST} MZB</h2>
                                </div>
                                <div className="money">
                                    <span className="text-primary d-block">~ {balanceRewardBUSD} BUSD</span>
                                </div>
                            </div>
                            <button className="btn rotated-btn">
                                <div className="fs-btn-wrap">
                                    <FiUpload /> Withdraw MZB
                                </div>
                            </button>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12">
                        <div className="wallet-box">
                            <span className="text-white">Remaining Reward Token</span>
                            <div className="money-wrap d-grid">
                                <div className="money">
                                    <h2 className="text-primary mb-2">{balanceRewardFST} MZB</h2>
                                </div>
                                <div className="money">
                                    <span className="text-primary d-block">{balanceRewardBUSD} BUSD</span>
                                </div>
                            </div>
                            <button className="btn rotated-btn">
                                <div className="fs-btn-wrap">
                                    <FiAward /> Reward
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Wallet;
