import React, { useState, useEffect } from "react";

import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';

import { FiFileText, FiSend, FiRotateCw, FiX, FiCheck, FiChevronsUp, FiToggleRight, FiBookOpen } from "react-icons/fi";

import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import { NumericFormat } from 'react-number-format';

import {
    useHistory
} from "react-router-dom";

import { coin } from "../../assets/images/index.js";
import Logo from '../../assets/images/logo.png';
import token_busd from '../../assets/images/busd.png';
import close_img from '../../assets/images/close.png';
import bg_border from '../../assets/images/backgrounds/bg-video-old-3.svg';

const Portfolio = () => {

    const closeBtn = (
        <button className="close" onClick={() => setShowMenu(false)} type="button">
            <img src={close_img} alt="" />
        </button>
    );

    const closeBtnUpgrade = (
        <button className="close" onClick={() => setShowUpgradeLevel(false)} type="button">
            <img src={close_img} alt="" />
        </button>
    )

    const closeModalStaking = (
        <button className="close" onClick={() => setShowModalStaking(false)} type="button">
            <img src={close_img} alt="" />
        </button>
    )

    const [showMenu, setShowMenu] = useState(false);
    const [showUpgradeLevel, setShowUpgradeLevel] = useState(false);
    const [showModalStaking, setShowModalStaking] = useState(false);

    const [numberOfStake12Months, setNumberOfStake12Months] = useState(0);
    const [numberOfStake9Months, setNumberOfStake9Months] = useState(0);
    const [numberOfStake6Months, setNumberOfStake6Months] = useState(0);


    const [isEnableBUSD, setIsEnableBUSD] = useState(false);

    const [stakingList, setStakingList] = useState([]);
    const [historyStakingList, setHistoryStakingList] = useState([]);

    const [level, setLevel] = useState(0);
    const [priceMZB, setPriceMZB] = useState(0);

    const [stakedBUSDValue, setStakedBUSDValue] = useState(0);
    const [stakedMZBValue, setStakedMZBValue] = useState(0);
    const [earnedValue, setEarnedValue] = useState(0);
    const [revenueValueBUSD, setRevenueValueBUSD] = useState(0);
    const [revenueValueMZB, setRevenueValueMZB] = useState(0);

    const [historyRevenueInSponsor, setHistoryRevenueInSponsor] = useState([]);

    const [isLevel1, setIsLevel1] = useState(false);
    const [isLevel2, setIsLevel2] = useState(false);
    const [isLevel3, setIsLevel3] = useState(false);
    const [isLevel4, setIsLevel4] = useState(false);
    const [isLevel5, setIsLevel5] = useState(false);

    const [isRewardLevel1, setIsRewardLevel1] = useState(false);
    const [isRewardLevel2, setIsRewardLevel2] = useState(false);
    const [isRewardLevel3, setIsRewardLevel3] = useState(false);
    const [isRewardLevel4, setIsRewardLevel4] = useState(false);
    const [isRewardLevel5, setIsRewardLevel5] = useState(false);

    const handleShowRevenueModal = (event) => {
        setShowMenu(true);
    }

    const handleShowUpgradeLevel = (event) => {
        setShowUpgradeLevel(true);
    }

    const checkEnableBUSD = () => {
        setIsEnableBUSD(true);
    }

    return (
        <>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="mtz-profile_title text-white text-left mt-0">
                            Portfolio
                        </div>
                        <hr />
                    </div>
                    <div className="col-12">
                        <div className="number-wrap">
                            <div className="number-item earning">
                                <span>STAKED BUSD VALUE</span>
                                <NumericFormat type="text" value={stakedBUSDValue} displayType={'text'} thousandSeparator={true}
                                    renderText={(value, props) => <h2><span>{value}</span> <img src={token_busd} alt="" /></h2>} />
                                {/* <h2>{stakedBUSDValue} <img src={token_busd} alt="" /></h2> */}
                            </div>
                            <div className="number-item earning">
                                <span>EARNING</span>
                                <NumericFormat type="text" value={earnedValue} displayType={'text'} thousandSeparator={true}
                                    renderText={(value, props) => <h2><span>{value}</span> <img src={coin} alt="" /></h2>} />
                                {/* <h2>{earnedValue} <img src={coin} alt="" /></h2> */}
                            </div>
                            <div className="number-item earning">
                                <span>MZB PRICE</span>
                                <h2>{priceMZB} <img src={token_busd} alt="" /></h2>
                            </div>
                            <div className="number-item earning">
                                <span>STAKED MZB VALUE</span>
                                <NumericFormat type="text" value={stakedMZBValue} displayType={'text'} thousandSeparator={true}
                                    renderText={(value, props) => <h2><span>{value}</span> <img src={coin} alt="" /></h2>} />
                                {/* <h2>{stakedMZBValue} <img src={coin} alt="" /></h2> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt-5">
                        <Tab.Container defaultActiveKey="180days" >
                            <Nav variant="pills" className="portfolio-nav">
                                <Nav.Item>
                                    <Nav.Link eventKey="180days"><span><FiBookOpen /> 180 days</span></Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="270days"><span><FiBookOpen /> 270 days</span></Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="365days"><span><FiBookOpen /> 365 days</span></Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane eventKey="180days">
                                    <div className="wallet-box portfolio-box">
                                        <div className="info_name info_portfolio">
                                            <label className="text-white">180 days</label>
                                            <img className="mtz-icon" src={Logo} alt="" />
                                            <div className="connect-group">
                                                <div className="connect-input-wrap">

                                                    <input type="number" className="form-control" placeholder="0.00" />

                                                    <img src={token_busd} className="connect-icon" alt="" />
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <span>INTEREST RATE</span>
                                                    <span>9.00%</span>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <span>ISSUANCE FEE</span>
                                                    <span>0.00%</span>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <span>MIN STAKING</span>
                                                    <span>$200</span>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <span>MAX STAKING</span>
                                                    <span>$5,000</span>
                                                </div>
                                                <div className="name">
                                                    {
                                                        !isEnableBUSD ?
                                                            <button className="btn rotated-btn" onClick={checkEnableBUSD}>
                                                                <div className="fs-btn-wrap">
                                                                    <FiToggleRight /> Enable BUSD
                                                                </div>
                                                            </button>
                                                            :
                                                            <button className="btn rotated-btn">
                                                                <div className="fs-btn-wrap">
                                                                    <FiSend /> Stake
                                                                </div>
                                                            </button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-4">
                                            <div className="mtz-profile_title text-white text-left mt-0">
                                                Staking Lists
                                            </div>
                                            <hr />
                                        </div>
                                        <div className="col-lg-12 col-12">
                                            <div className="table-responsive portfolio-table-wrap mt-3">
                                                <table className="table table-bordered text-white">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col">Package Days</th>
                                                            <th scope="col">Created date</th>
                                                            <th scope="col">Estimate time</th>
                                                            <th scope="col">Capital BUSD</th>
                                                            <th scope="col">Profit BUSD</th>
                                                            <th scope="col">Earn MZB</th>
                                                            <th scope="col">Detail</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="270days">
                                    <div className="wallet-box portfolio-box">
                                        <div className="info_name info_portfolio">
                                            <label className="text-white">270 days</label>
                                            <img className="mtz-icon" src={Logo} alt="" />
                                            <div className="connect-group">
                                                <div className="connect-input-wrap">
                                                    <input type="number" className="form-control" placeholder="0.00" />
                                                    <img src={token_busd} className="connect-icon" alt="" />
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <span>INTEREST RATE</span>
                                                    <span>10.00%</span>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <span>ISSUANCE FEE</span>
                                                    <span>0.00%</span>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <span>MIN STAKING</span>
                                                    <span>$300</span>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <span>MAX STAKING</span>
                                                    <span>$10,000</span>
                                                </div>
                                                <div className="name">
                                                    {
                                                        !isEnableBUSD ?
                                                            <button className="btn rotated-btn" onClick={checkEnableBUSD}>
                                                                <div className="fs-btn-wrap">
                                                                    <FiToggleRight /> Enable BUSD
                                                                </div>
                                                            </button>
                                                            :
                                                            <button className="btn rotated-btn">
                                                                <div className="fs-btn-wrap">
                                                                    <FiSend /> Stake
                                                                </div>
                                                            </button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-4">
                                            <div className="mtz-profile_title text-white text-left mt-0">
                                                Staking Lists
                                            </div>
                                            <hr />
                                        </div>
                                        <div className="col-lg-12 col-12">
                                            <div className="table-responsive portfolio-table-wrap mt-3">
                                                <table className="table table-bordered text-white">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col">Package Days</th>
                                                            <th scope="col">Created date</th>
                                                            <th scope="col">Estimate time</th>
                                                            <th scope="col">Capital BUSD</th>
                                                            <th scope="col">Profit BUSD</th>
                                                            <th scope="col">Earn MZB</th>
                                                            <th scope="col">Detail</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="365days">
                                    <div className="wallet-box portfolio-box">
                                        <div className="info_name info_portfolio">
                                            <label className="text-white">365 days</label>
                                            <img className="mtz-icon" src={Logo} alt="" />
                                            <div className="connect-group">
                                                <div className="connect-input-wrap">
                                                    <input type="number" className="form-control" placeholder="0.00" />
                                                    <img src={token_busd} className="connect-icon" alt="" />
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <span>INTEREST RATE</span>
                                                    <span>12.00%</span>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <span>ISSUANCE FEE</span>
                                                    <span>0.00%</span>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <span>MIN STAKING</span>
                                                    <span>$500</span>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <span>MAX STAKING</span>
                                                    <span>$20,000</span>
                                                </div>
                                                <div className="name">
                                                    {
                                                        !isEnableBUSD ?
                                                            <button className="btn rotated-btn" onClick={checkEnableBUSD}>
                                                                <div className="fs-btn-wrap">
                                                                    <FiToggleRight /> Enable BUSD
                                                                </div>
                                                            </button>
                                                            :
                                                            <button className="btn rotated-btn">
                                                                <div className="fs-btn-wrap">
                                                                    <FiSend /> Stake
                                                                </div>
                                                            </button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-4">
                                            <div className="mtz-profile_title text-white text-left mt-0">
                                                Staking Lists
                                            </div>
                                            <hr />
                                        </div>
                                        <div className="col-lg-12 col-12">
                                            <div className="table-responsive portfolio-table-wrap mt-3">
                                                <table className="table table-bordered text-white">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col">Package Days</th>
                                                            <th scope="col">Created date</th>
                                                            <th scope="col">Estimate time</th>
                                                            <th scope="col">Capital BUSD</th>
                                                            <th scope="col">Profit BUSD</th>
                                                            <th scope="col">Earn MZB</th>
                                                            <th scope="col">Detail</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                    <div className="col-12 mt-5">
                        <div className="mtz-profile_title text-white text-left mt-0">
                            Revenue Info
                        </div>
                        <hr />
                    </div>
                    <div className="col-lg-12 col-12">
                        <div className="revenue-wrap number-wrap">
                            <div className="number-item earning">
                                <span>Total Revenue BUSD</span>
                                <NumericFormat type="text" value={revenueValueBUSD} displayType={'text'} thousandSeparator={true}
                                    renderText={(value, props) => <h2>{value} <img src={token_busd} alt="" /></h2>} />
                                {/* <h2>{revenueValueBUSD} <img src={token_busd} alt="" /></h2> */}
                            </div>
                            <div className="number-item earning">
                                <span>Total Revenue MZB</span>
                                <NumericFormat type="text" value={revenueValueMZB} displayType={'text'} thousandSeparator={true}
                                    renderText={(value, props) => <h2>{value} <img src={coin} alt="" /></h2>} />
                                {/* <h2>{revenueValueMZB} <img src={coin} alt="" /></h2> */}
                            </div>
                            <div className="number-item earning">
                                <span>Your Level</span>
                                <h2>{level}</h2>
                                <button className="btn rotated-btn"
                                    onClick={event => handleShowUpgradeLevel(event)}
                                >
                                    <div className="fs-btn-wrap">
                                        <FiChevronsUp /> Upgrade Level
                                    </div>
                                </button>
                            </div>
                            <div className="number-item earning">
                                <span>List Revenue</span>
                                <button className="btn rotated-btn"
                                    onClick={event => handleShowRevenueModal(event)}
                                >
                                    <div className="fs-btn-wrap">
                                        <FiFileText /> Show List
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt-4">
                        <div className="mtz-profile_title text-white text-left mt-0">
                            Referral Staking Program
                        </div>
                        <hr />
                    </div>
                    <div className="col-lg-12 col-12">
                        <div className="table-responsive rule-wrap mt-3">
                            <table className="table table-bordered text-white">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Referral</th>
                                        <th scope="col">Reward</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>TIE1</td>
                                        <td>7%</td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>TIE2</td>
                                        <td>4%</td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>TIE3</td>
                                        <td>2%</td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>TIE4</td>
                                        <td>1%</td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td>TIE5</td>
                                        <td>1%</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <Modal className="modal-connect" fade={true} centered={true} isOpen={showMenu} toggle={() => setShowMenu(false)}>
                <ModalHeader toggle={() => setShowMenu(false)} close={closeBtn} >Revenue Info</ModalHeader>
                <ModalBody>
                    <div className={historyRevenueInSponsor.length > 10 ? "table-responsive portfolio-table-wrap table-modal mt-3 table-scroll" : "table-responsive portfolio-table-wrap table-modal mt-3"}>
                        <table className="table table-bordered text-white">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Address</th>
                                    <th scope="col">Created Date</th>
                                    <th scope="col">Capital</th>
                                    <th scope="col">Package Days</th>
                                    <th scope="col">Downline Level</th>
                                    <th scope="col">Commission BUSD</th>
                                    <th scope="col">Commission MZB</th>
                                </tr>
                            </thead>
                            <tbody>
                            </tbody>
                        </table>
                    </div>
                </ModalBody>
            </Modal>
            <Modal className="modal-connect" fade={true} centered={true} isOpen={showUpgradeLevel} toggle={() => setShowUpgradeLevel(false)}>
                <ModalHeader toggle={() => setShowUpgradeLevel(false)} close={closeBtnUpgrade} >Upgrade Level</ModalHeader>
                <ModalBody>
                    <div className="table-responsive portfolio-table-wrap table-modal mt-3">
                        <table className="table table-bordered text-white">
                            <thead>
                                <tr>
                                    <th scope="col">Level</th>
                                    <th scope="col">Coditions</th>
                                    <th scope="col">Action</th>
                                    <th scope="col">Status</th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr>
                                    <td>1</td>
                                    <td>You have a total revenue of $100,000</td>
                                    <td>
                                        <button className="btn rotated-btn" disabled={!isLevel1}>
                                            <div className="fs-btn-wrap">
                                                <FiRotateCw /> Claim
                                            </div>
                                        </button>
                                    </td>
                                    <td>
                                        {
                                            !isRewardLevel1 ?
                                                <div className="not-claim">
                                                    <FiX />
                                                </div>
                                                :
                                                <div className="claimed">
                                                    <FiCheck />
                                                </div>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>You have at least 2 TIE1s attend to Level 1</td>
                                    <td>
                                        <button className="btn rotated-btn" disabled={!isLevel2}>
                                            <div className="fs-btn-wrap">
                                                <FiRotateCw /> Claim
                                            </div>
                                        </button>
                                    </td>
                                    <td>
                                        {
                                            !isRewardLevel2 ?
                                                <div className="not-claim">
                                                    <FiX />
                                                </div>
                                                :
                                                <div className="claimed">
                                                    <FiCheck />
                                                </div>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>You have at least 2 TIE1s attend to Level 2</td>
                                    <td>
                                        <button className="btn rotated-btn" disabled={!isLevel3}>
                                            <div className="fs-btn-wrap">
                                                <FiRotateCw /> Claim
                                            </div>
                                        </button>
                                    </td>
                                    <td>
                                        {
                                            !isRewardLevel3 ?
                                                <div className="not-claim">
                                                    <FiX />
                                                </div>
                                                :
                                                <div className="claimed">
                                                    <FiCheck />
                                                </div>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>You have at least 2 TIE1s attend to Level 3</td>
                                    <td>
                                        <button className="btn rotated-btn" disabled={!isLevel4}>
                                            <div className="fs-btn-wrap">
                                                <FiRotateCw /> Claim
                                            </div>
                                        </button>
                                    </td>
                                    <td>
                                        {
                                            !isRewardLevel4 ?
                                                <div className="not-claim">
                                                    <FiX />
                                                </div>
                                                :
                                                <div className="claimed">
                                                    <FiCheck />
                                                </div>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>You have at least 2 TIE1s attend to Level 4</td>
                                    <td>
                                        <button className="btn rotated-btn" disabled={!isLevel5}>
                                            <div className="fs-btn-wrap">
                                                <FiRotateCw /> Claim
                                            </div>
                                        </button>
                                    </td>
                                    <td>
                                        {
                                            !isRewardLevel5 ?
                                                <div className="not-claim">
                                                    <FiX />
                                                </div>
                                                :
                                                <div className="claimed">
                                                    <FiCheck />
                                                </div>
                                        }
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </ModalBody>
            </Modal>
            {/* Staking List Modal */}
            <Modal className="modal-connect" fade={true} centered={true} isOpen={showModalStaking} toggle={() => setShowModalStaking(false)}>
                <ModalHeader toggle={() => setShowModalStaking(false)} close={closeModalStaking} >Staking Withdraw History</ModalHeader>
                <ModalBody>
                    <div className={historyStakingList.length > 10 ? "history-detail-wrap table-responsive table-scroll" : "history-detail-wrap table-responsive"}>
                        <table className="table table-bordered text-white table-hover">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Amount BUSD</th>
                                    <th>Amount MZB</th>
                                    <th>Earned</th>
                                    <th>Price MZB</th>
                                    <th>PayDay</th>
                                </tr>
                            </thead>
                            <tbody>
                                
                            </tbody>
                        </table>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
}

export default Portfolio;
