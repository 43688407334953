import React from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import PageTitle from "../../components/Page_Title/Page_Title";
import { BiJoystick , BiShareAlt } from "react-icons/bi";
import { SiBlockchaindotcom } from "react-icons/si";
import { FiLinkedin } from "react-icons/fi";

import { backGrounds } from "../../assets/images/index.js";
import { teams_core } from "../../assets/images/index";

// let teams_core = [
//     {
// 		img: teams_img.CoreTeam_CamCo,
// 		name: 'Clark Ha',
// 		office: 'CEO',
// 		linkedin: 'https://www.linkedin.com/in/c%C6%A1-h%C3%A0-007068216'
// 	},
//     {
// 		img: teams_img.CoreTeam_HuyLe,
// 		name: 'Henry Le',
// 		office: 'Strategic Investor',
// 		linkedin: 'https://www.linkedin.com/in/huy-l%C3%AA-343134154'
// 	},
//     {
// 		img: teams_img.CoreTeam_KhoaNguyen,
// 		name: 'Khoa Nguyen',
// 		office: 'CMO',
// 		linkedin: 'https://www.linkedin.com/in/khoa-nguy%E1%BB%85n-37b259223'
// 	},
//     {
// 		img: teams_img.CoreTeam_KhoaCao,
// 		name: 'Khoa Cao',
// 		office: 'Game Developer',
// 		linkedin: 'https://www.linkedin.com/in/khoa-cao-3ba6a478/'
// 	},
//     {
// 		img: teams_img.CoreTeam_PhuongNguyen,
// 		name: 'Phuong Nguyen',
// 		office: 'Game Leader',
// 		linkedin: 'https://www.linkedin.com/in/thanh-ph%C6%B0%C6%A1ng-nguy%E1%BB%85n-b5916b224/'
// 	},
//     {
// 		img: teams_img.CoreTeam_Nhile,
// 		name: 'Nhi Le',
// 		office: 'Game Developer',
// 		linkedin: 'https://www.linkedin.com/in/nhi-l%C3%AA-133172224/'
// 	},
//     {
// 		img: teams_img.CoreTeam_HaoNguyen,
// 		name: 'Nhat Hao',
// 		office: 'Full-Stack Developer',
// 		linkedin: 'https://www.linkedin.com/in/nguyen-nhat-hao-05794a167'
// 	},
//     {
// 		img: teams_img.CoreTeam_DuyNguyen,
// 		name: 'Harvey Nguyen',
// 		office: 'Global Chief Marketing Officer (CMO)',
// 		linkedin: 'https://www.linkedin.com/in/duy-nguyen-033135121/'
// 	},
// 	{
// 		img: teams_img.CoreTeam_DucNguyen,
// 		name: 'Jack Nguyen',
// 		office: 'Chief Finance Officer (CFO)',
// 		linkedin: 'https://www.linkedin.com/in/%C4%91%E1%BB%A9c-nguy%E1%BB%85n-5b74ba223/'
// 	},
//     {
// 		img: teams_img.CoreTeam_TienDung,
// 		name: 'Tien Dung',
// 		office: 'Global Lead Marketing',
// 		linkedin: 'https://www.linkedin.com/in/edwarditmo07'
// 	},
// 	{
// 		img: teams_img.CoreTeam_LoiLe,
// 		name: 'Scott Phan',
// 		office: 'Communication Specialist',
// 		linkedin: 'https://www.linkedin.com/in/scott-phan-b592b0234/'
// 	},
//     {
// 		img: teams_img.CoreTeam_KhanhNguyen,
// 		name: 'Lee Changho',
// 		office: 'Art Designer',
// 		linkedin: 'https://www.linkedin.com/in/khanh-nguyen-19a638224/'
// 	},
//     {
// 		img: teams_img.CoreTeam_HuyTrinh,
// 		name: 'Huy Trinh',
// 		office: 'Designer',
// 		linkedin: 'https://www.linkedin.com/in/trinh-huy-379083223/'
// 	}
// ];

function AboutPage() {
    return (
       <>
            <PageTitle 
                title="ABOUT US" 
                desc="GET TO KNOW US AND METAZOMBIES VR MORE!"
                background={backGrounds[6]}
            />
            <section className="about-us-page pt-90 pb-90">
                <div className="about-circle-shape">
                    <h1 className="text">About us</h1>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <Tabs className="tabs-vr">
                                <TabList className="tabs-vr-head">
                                    {/* <Tab>Vision & Goal</Tab> */}
                                    {/* <Tab>Team & Culture</Tab> */}
                                </TabList>

                                <TabPanel className="tabs-vr-body">
                                    <div className="vision-title">
                                        <h1>VISION</h1>
                                    </div>
                                    <div className="vision-card-wrap">
                                        <div className="vision-card">
                                            <div className="icon">
                                                <BiShareAlt size={32} />
                                            </div>
                                            <h3 className="text-uppercase">Community-driven game</h3>
                                            <span>Building a community-driven game that allows players to become owners/investors.</span>
                                        </div>
                                        <div className="vision-card">
                                            <div className="icon">
                                                <BiJoystick size={32} />
                                            </div>
                                            <h3 className="text-uppercase">A game both you and we wish to experience</h3>
                                            <span>Creating a game that you and we both want to play. Beautiful design and the most advanced technology will be a great combination to bring best experience to players.</span>
                                        </div>
                                        <div className="vision-card">
                                            <div className="icon">
                                                <SiBlockchaindotcom size={32} />
                                            </div>
                                            <h3 className="text-uppercase">Blockchain technology knowledge</h3>
                                            <span>Bringing blockchain technology to the public, access more easily through game experience.</span>
                                        </div>
                                    </div>
                                    <div className="vision-title">
                                        <h1>GOAL</h1>
                                    </div>
                                    
                                    <div className="vision-text">
                                        <div className="roadmap-head">
                                            <span>Hello gunners! Welcome to our Goal. As you may know our goal is to create a game:</span>
                                            {/* <div className="tag">Q2 2021</div> */}
                                            <ul>
                                                <li className="info">Tell an interesting story for players – We hope to reach a large number of users. Especially gamers who are passionate about dramatic adventure shooting games.</li>
                                                <li className="info">Allow you to fully possess of all your in-game assets (characters, items, in-game currency, equipment, lands, etc.) and offer you a chance to make real money from playing the game (P2E model).</li>
                                                <li className="info">Accomplish our main goal and vision, there are various stages that we have to complete step by step. Each of these stages is described in our roadmap</li>
                                            </ul>
                                        </div>
                                        {/* <div className="roadmap-head">
                                            <div className="tag">Q3-Q4 2021</div>
                                            <ul>
                                                <li className="info">Design UI and create in-game content</li>
                                                <li className="info">Create a logo for the game</li>
                                                <li className="info">Create pitch desk</li>
                                                <li className="info">Launch social media channels</li>
                                                <li className="info">Exclusive NFT design brainstorming</li>
                                            </ul>
                                        </div>
                                        <div className="roadmap-head">
                                            <div className="tag">Q1 2022</div>
                                            <ul>
                                                <li className="info">Website launching</li>
                                                <li className="info">Social & Marketing</li>
                                                <li className="info">Seeking for partners</li>
                                                <li className="info">Behind the Scenes of Game development</li>
                                            </ul>
                                        </div>
                                        <div className="roadmap-head">
                                            <div className="tag">Q2 2022</div>
                                            <ul>
                                                <li className="info">Open private round</li>
                                                <li className="info">Whitelist for the first 200 NFTs</li>
                                                <li className="info">Launch the Demo in Virtual Reality (VR)</li>
                                                <li className="info">Game Instructions</li>
                                                <li className="info">Introducing the exclusive benefits of owning NFT: membership can join the community and participate in the game to earn NFTs and Tokens.</li>
                                                <li className="info">Airdrop Metazombie Tokens to the first 200 NFT holders</li>
                                                <li className="info">Public Sale of the remaining NFTs</li>
                                                <li className="info">Launch Testnet</li>
                                            </ul>
                                        </div>
                                        <div className="roadmap-head">
                                            <div className="tag">Q3 2022</div>
                                            <ul>
                                                <li className="info">Mainnet: Launch the game in Virtual Reality (VR), allow members to participate in the game</li>
                                                <li className="info">PVE mode</li>
                                                <li className="info">Co-op mode</li>
                                                <li className="info">Listing tokens and Add Liquidity</li>
                                            </ul>
                                        </div>
                                        <div className="roadmap-head">
                                            <div className="tag">Q4 2022</div>
                                            <ul>
                                                <li className="info">Launch Marketplace</li>
                                                <li className="info">List on CEX</li>
                                                <li className="info">Publish extra 10,000 NFTs for new users</li>
                                                <li className="info">Update Game: game expansion, add new modes, weapons, zombies,...</li>
                                                <li className="info">Launch PVP game mode</li>
                                                <li className="info">Ranked PVP</li>
                                            </ul>
                                        </div>
                                        <div className="roadmap-head">
                                            <div className="tag">Q1 2023</div>
                                            <ul>
                                                <li className="info">Adding new characters</li>
                                                <li className="info">Conduct online, offline tournaments</li>
                                                <li className="info">Guild Wars feature</li>
                                            </ul>
                                        </div>
                                        <div className="roadmap-head">
												<div className="tag">Q2 2023</div>
												<ul>
													<li className="info">Release MetaZombies VR comic</li>
													<li className="info">Adding NFT Lands (ownable area of digital land)</li>
													<li className="info">Organize the 2nd online, offline tournaments</li>
												</ul>
											</div> */}
                                    </div>
                                </TabPanel>
                                {/* <TabPanel className="tabs-vr-body">
                                    <div className="vision-title">
                                        <h1>OUR CULTURE</h1>
                                    </div>
                                    <span className="text-center d-block">
                                    Development team always plays an important role in the success of a project.<br/> We’ve been working as a team for almost 5 years, and everyone has extensive experience in their respective fields.
                                    </span>
                                    <div className="about-team-wrap pt-90">
                                        <div className="vision-title">
                                            <h1>TEAM</h1>
                                        </div>
                                       
                                        <div className="about-team-item-four">
                                            {
                                                teams_core.map( (item, index) => {
                                                    return(
                                                        <div className="about-team-item" key={index}>
                                                            <LazyLoadImage
                                                                loading="lazy"
                                                                alt=""
                                                                src={item.img}
                                                                effect="blur"
                                                            /> 
                                                            <h4>{item.name}</h4>
                                                            <span>{item.office}</span>
                                                            <div className="social-overlay">
                                                                <a href={item.linkedin} rel="noreferrer" className="linkedin" target='_blank'>
                                                                    <FiLinkedin />
                                                                </a>
                                                            </div>    
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </TabPanel> */}
                            </Tabs>
                        </div>
                    </div>
                </div>
            </section>
       </>
    );
}

export default AboutPage;
