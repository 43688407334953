import React, { useState, useEffect, useRef } from "react";
import {
    Link,
    useHistory,
    useLocation
} from "react-router-dom";

import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import { ToastProvider, useToasts } from 'react-toast-notifications';

import NumberFormat from 'react-number-format';

import {
    FiAlignJustify,
    FiX,
    FiChevronDown,
    FiLogOut,
    FiAlignRight,
    FiUser,
    FiUsers,
    FiCreditCard
} from "react-icons/fi";

import { } from "react-icons/fi";


import Logo from '../../assets/images/logo.png';
import { coin } from "../../assets/images/index.js";
import token_busd from '../../assets/images/busd.png';
import Metamask_Icon from '../../assets/images/metamask-icon.png';
import Wallet_Icon from '../../assets/images/walletconnect.svg';
import close_img from '../../assets/images/close.png';

import './Header.css';


import { useWeb3React } from "@web3-react/core";
import { connectors } from "../../utils/connectors";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}


function HeaderPage(props) {

    const history = useHistory();
    const { addToast } = useToasts();

    const {
        library,
        activate,
        active,
        chainId,
        account
    } = useWeb3React();

    const location = useLocation();
    const setProvider = (type) => {
        window.localStorage.setItem("provider", type);
    };

    const [showMenu, setShowMenu] = useState(false);
    const [showProfile, setShowProfile] = useState(false);

    const [network, setNetwork] = useState(typeof window.ethereum !== 'undefined' ? window.ethereum.networkVersion : 56);
    const [checkConnect, setCheckConnect] = useState(false);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const myRef = useRef();
    const refMobile = useRef();

    const [balanceBNB, setBalanceBNB] = useState(0);
    const [balanceMZB, setBalanceMZB] = useState(0);
    const [balanceBUSD, setBalanceBUSD] = useState(0);

    const closeBtn = (
        <button className="close" onClick={() => setCheckConnect(false)} type="button">
            <img src={close_img} alt="" />
        </button>
    );

    const handleClickOutside = (e) => {
        if (!myRef.current.contains(e.target)) {
            setShowProfile(false);
        } else {
            return true;
        }
    };

    const handleClickOutsideMobile = (e) => {
        if (!myRef.current.contains(e.target)) {
            setShowMenu(false);
        } else {
            return true;
        }
    };

    const handleClickInside = () => setShowProfile(!showProfile);

    const handleInjected = async () => {
        if (typeof window.ethereum !== 'undefined') {
            const currentNetwork = window.ethereum.networkVersion;
            switch (parseInt(currentNetwork)) {
                case 1:
                    activate(connectors.injected1);
                    setNetwork(1);
                    break;
                case 56:
                    activate(connectors.injected56);
                    setNetwork(56);
                    break;
                case 97:
                    activate(connectors.injected97);
                    setNetwork(97);
                    break;
                default:
                    activate(connectors.injected56);
                    break;
            }
            setProvider(String("injected" + currentNetwork));
            setCheckConnect(false);
            addToast('Wallet Connected', {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 2000
            });
            setTimeout(() => {
                history.push("/dashboard");
            }, 2000);
        } else {
            window.open("https://metamask.io", '_blank');
        }
    }

    const handleWalletConnect = async () => {
        var walletconnectchain;
        console.log(parseInt(network))
        switch (parseInt(network)) {
            case 1:
                walletconnectchain = connectors.walletconnect1;
                setNetwork(1);
                break;
            case 56:
                walletconnectchain = connectors.walletConnect56;
                setNetwork(56);
                break;
            case 97:
                walletconnectchain = connectors.walletConnect97;
                setNetwork(97);
                break;
            default:
                walletconnectchain = connectors.walletConnect56;
                break;
        }
        activate(walletconnectchain);
        setProvider(String("walletConnect" + walletconnectchain.chainId));
        history.push("/dashboard");
        setCheckConnect(false);
    }

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);
        if (windowDimensions.width >= 767) {
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                window.removeEventListener('resize', handleResize);
                document.removeEventListener('mousedown', handleClickOutside);
            }
        } else {
            document.addEventListener('mousedown', handleClickOutside);
            document.addEventListener('mousedown', handleClickOutsideMobile);
            return () => {
                window.removeEventListener('resize', handleResize);
                document.removeEventListener('mousedown', handleClickOutside);
                document.removeEventListener('mousedown', handleClickOutsideMobile);
            }
        }
    }, [windowDimensions]);

    return (
        <>
            <header className="header-style-four">
                <div id="sticky-header" className="header-four-wrap">
                    <div className="container custom-container-two">
                        <div className="row">
                            <div className="col-12">
                                <div className="main-menu menu-style-two">
                                    <nav>
                                        <div className="logo">
                                            <a href="/"><img src={Logo} alt="Logo" /></a>
                                        </div>
                                        <div className="menu-flex-mobile navbar-wrap d-lg-flex">
                                            <ul>
                                                <li><Link to="/">Home</Link></li>
                                                <li><Link to="/about-us">About Us</Link></li>
                                                <li><Link to="/world-of-metazombies-vr">World Of Metazombies VR</Link></li>
                                                <li><Link to="/gallery">Gallery</Link></li>
                                                <li className="d-md-block">
                                                    {
                                                        !active && typeof account == 'undefined' ?
                                                            <button
                                                                className="btn wow fadeInUp btn-connectWallet"
                                                                onClick={() => {
                                                                   // setCheckConnect(true)
                                                                }}
                                                            >
                                                                Connect Wallet
                                                            </button>
                                                            :
                                                            <div
                                                                className="mtz-toggle_box mtz-profile_box"
                                                                onClick={() => {
                                                                    setShowProfile(!showProfile)
                                                                    handleClickInside()
                                                                }}
                                                                ref={myRef}
                                                            >
                                                                <div className={
                                                                    showProfile === false ?
                                                                        "mtz-toggle_btn mtz-header_icon_btn mtz-center"
                                                                        :
                                                                        "mtz-toggle_btn mtz-header_icon_btn mtz-center active"
                                                                }
                                                                >
                                                                    <FiUser />
                                                                    <span>
                                                                        {`${account.slice(0, 6)}...${account.slice(
                                                                            account.length - 4,
                                                                            account.length
                                                                        )}`}
                                                                    </span>
                                                                    <FiChevronDown />
                                                                </div>
                                                                <div className={showProfile === false ? "mtz-toggle_body" : "mtz-toggle_body active"}>
                                                                    <div className="mtz-user_info">
                                                                        <span>Your Address</span>
                                                                        <h4 className="mtz-user_address">
                                                                            {`${account.slice(0, 6)}...${account.slice(
                                                                                account.length - 4,
                                                                                account.length
                                                                            )}`}
                                                                        </h4>
                                                                    </div>
                                                                    <div className="mtz-user_info">
                                                                        <span>Your Balance</span>
                                                                        <h4 className="mtz-user_balance">10000.0000 <img src={token_busd} alt="" /></h4>
                                                                        <h4 className="mtz-user_balance">10000.0000 <img src={coin} alt="" /></h4>
                                                                    </div>
                                                                    <hr />
                                                                    <ul>
                                                                        <li><Link to="/dashboard"><FiUsers />Dashboard</Link></li>
                                                                        <li><Link to="/shop"><FiCreditCard />Shop</Link></li>
                                                                        <li><Link to="#"><FiUser />Your Items</Link></li>
                                                                        <li ref={myRef}>
                                                                            <div onClick={() => {
                                                                                props.disconnect();
                                                                                return history.push('/shop');
                                                                            }}><FiLogOut /> Logout</div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                    }
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="mobile-button-menu">
                                            <FiAlignJustify size={32} onClick={() => setShowMenu(true)} />
                                        </div>
                                    </nav>
                                </div>
                                <div className={showMenu ? "mobile-menu show" : "mobile-menu"}>
                                    <div className="menu-mobile-wrap navbar-wrap ">
                                        <FiX size={32} onClick={() => setShowMenu(false)} />
                                        <ul>
                                            <li><Link to="/">Home</Link></li>
                                            <li><Link to="/about-us">About Us</Link></li>
                                            <li><Link to="/world-of-metazombies-vr">World Of Metazombies VR</Link></li>
                                            <li><Link to="/gallery">Gallery</Link></li>
                                            {/* <li><a href="#nft">Items</a></li>
                                            <li><a href="#roadmap">Roadmap</a></li>
                                            <li><a href="#teams">Team</a></li> */}
                                            {/* <li><button className="btn wow fadeInUp btn-connectWallet">Connect Wallet</button></li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <Modal
                className="modal-connect"
                fade={true}
                centered={true}
                isOpen={checkConnect}
                toggle={() => setCheckConnect(false)}
            >
                <ModalHeader toggle={() => setCheckConnect(false)} close={closeBtn} >Connect To A Wallet</ModalHeader>
                <ModalBody>
                    <div className="connect-wallet-wrap">
                        <div className="switch_wallet_network">
                            <div className="switch_network_item"
                                onClick={() => handleInjected()}
                            >
                                <img src={Metamask_Icon} alt="" />
                                <span>Metamask</span>
                            </div>
                            <div className="switch_network_item"
                                onClick={() => handleWalletConnect()}
                            >
                                <img src={Wallet_Icon} alt="" />
                                <span>Wallet Connect</span>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>

    );
}

export default HeaderPage;
