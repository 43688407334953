import React, { useState, useMemo } from "react";

import { Swiper, SwiperSlide } from "swiper/react";

import Pagi from "../../components/Pagination/Pagination";
import PageTitle from "../../components/Page_Title/Page_Title";

import icon_metamask from '../../assets/images/metamask-icon.png';
import { coin } from "../../assets/images/index.js";
import { backGrounds } from "../../assets/images/index.js";
import { nfts } from "../../assets/images/index.js";

// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import SwiperCore, { Autoplay } from 'swiper';
SwiperCore.use([Autoplay]);

const data_table_history = [
    {
        id: 1,
        tokenId: '10'
    },
    {
        id: 2,
        tokenId: '10'
    },
    {
        id: 3,
        tokenId: '10'
    },
    {
        id: 4,
        tokenId: '10'
    },
    {
        id: 5,
        tokenId: '10'
    },
    {
        id: 6,
        tokenId: '10'
    },
    {
        id: 7,
        tokenId: '10'
    },
    {
        id: 8,
        tokenId: '10'
    }
];

const PageSize_Transaction = 6;

const MintNft = ({ web3, address }) => {

    const [isConnect, checkConnect] = useState(false);
    const [page, setPage] = useState(1);

    const [historyListData, setHistoryListData] = useState(data_table_history);

    const currentDataTransaction = useMemo(() => {
        const firstPageIndex = (page - 1) * PageSize_Transaction;
        const lastPageIndex = firstPageIndex + PageSize_Transaction;
        return historyListData.slice(firstPageIndex, lastPageIndex);
    }, [historyListData, page]);


    return (
        <>
            <PageTitle
                title="MINT NFT"
                desc="Get your ZOMBIES NFT now"
                background={backGrounds[0]}
            />
            <section className="mint-page pt-90 pb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="box-connect-wrap">
                                <span className={!isConnect ? "text-center title-mint" : "text-left title-mint"}>Mint</span>
                                {
                                    !isConnect ?

                                        <div className="box-prev-connect text-center">
                                            <span className="mb-3 d-block">You need to connect your MetaMask wallet before you can mint NFT.</span>
                                            <button
                                                className="btn btn-connect-metaMask d-flex align-items-center m-auto"
                                                onClick={() => checkConnect(true)}
                                            >
                                                <img src={icon_metamask} alt="" />
                                                <span>Connect MetaMask</span>
                                            </button>
                                        </div>
                                        :
                                        <div className="box-connect text-center">
                                            <div className="box-connect-head">
                                                <div className="box-connect-head-left">
                                                    <span>ACCOUNT</span>
                                                    <span>0x9e05e646a18bb4cab41aa7992959f019d0aac124</span>
                                                </div>
                                                <div className="box-connect-head-right">
                                                    <span>BALANCE NFT</span>
                                                    <div className="box-coin"><span>0 NFT</span><img src={coin} alt="" /></div>
                                                </div>
                                            </div>
                                            <div className="box-connect-body">
                                                <span className="text-danger text-left d-block">Error: Could not fetch tokens.</span>
                                                <div className="get-nft text-center mt-2">
                                                    {/* <h3 className="d-block">Get your ZOMBIES NFT now</h3>
                                                    <img src={backGrounds[4]} alt="" /> */}
                                                    <button className="btn btn-mint mt-3">
                                                        <span>Mint</span>
                                                    </button>
                                                </div>
                                                {/* <div className="list-nft">
                                                    {
                                                        nfts.map((item, index) => {
                                                            return (
                                                                <div className="nft-item" key={index}>
                                                                    <img src={item} alt="" />
                                                                    <span>ZOOMBIE #{index}</span>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div> */}
                                            </div>
                                        </div>
                                }
                            </div>
                            <span className="d-block text-center mt-3">VERIFIED SMART CONTRACT ADDRESS: 0x9e05e646a18bb4cab41aa7992959f019d0aac124</span>
                        </div>
                        {
                            !isConnect ?
                                ""
                                :
                                <div className="col-12 mt-5">
                                    <div className="box-history-wrap">
                                        <span className="text-left title-mint">History mint nft</span>
                                        <div className="table-responsive">
                                            <table className="table table-hover mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>Address</th>
                                                        <th className="text-center">Token ID</th>
                                                        <th className="text-center">createdAtDate</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {currentDataTransaction.map(item => {
                                                        return (
                                                            <tr className="table__body__history" key={item.id}>
                                                                <td className="table__td">
                                                                    0x9e05e646a18bb4cab41aa7992959f019d0aac124
                                                                </td>
                                                                <td className="text-center">{item.tokenId}</td>
                                                                <td className="text-center">2022-10-10 9:20</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                            <Pagi
                                                className="pagination-bar"
                                                currentPage={page}
                                                totalCount={historyListData.length}
                                                pageSize={PageSize_Transaction}
                                                onPageChange={page => setPage(page)}
                                            />
                                        </div>
                                    </div>
                                </div>
                        }
                        <div className="col-12 mt-5">
                            <span className="text-center title-mint mb-0">ZOMBIES NFT</span>
                            <span className="text-center d-block w-75 m-auto">10,000 unique NFTs meticulously illustrated by our artists. Inspired by the Metazombies VR game itself, aptly named “Call of Survival”. All 10,000 NFT owners will be able to participate in the MetaZombies VR game.</span>
                            <Swiper
                                spaceBetween={20}
                                slidesPerView={1}
                                autoplay={{
                                    delay: 500,
                                    disableOnInteraction: false,
                                }}
                                modules={[Autoplay]}
                                loop={true}
                                className="mint-list-nft mt-3"
                            >
                                {
                                    nfts.map((item, index) => (
                                        <SwiperSlide key={index}>
                                            <div className="mint-nft-item">
                                                <img src={item} alt="" />
                                            </div>
                                        </SwiperSlide>
                                    ))
                                }
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default MintNft;
