import React from "react";
import {
    Link
} from "react-router-dom";

import { FaTwitter, FaDiscord, FaTelegramPlane } from "react-icons/fa";

function FooterPage() {

    return (
        <div className="copyright-wrap">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-8 col-12">
                        <div className="copyright-left">
                            <div className="copyright-text">
                                <p>Copyright © 2022 <a href="/" rel="noreferrer">Metazombies VR</a> All Rights Reserved.</p>
                            </div>
                            <ul className="list-link-footer">
                                <li>
                                    <Link className="box-img-flex" to="/term-and-conditions" rel="noreferrer">
                                        Terms & Conditions
                                    </Link>
                                </li>
                                <li>
                                    <Link className="box-img-flex" to="/faq" rel="noreferrer">
                                        FAQ
                                    </Link>
                                </li>
                                <li>
                                    <Link className="box-img-flex" to="/disclaimer" rel="noreferrer">
                                    Disclaimer
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-12">
                        <div className="list-social">
                            {/* <li>
                                <a className="box-img-flex" href="https://www.facebook.com/Metaracers" rel="noreferrer">
                                    <FaFacebook />
                                </a>
                            </li> */}
                            <li>
                                <a className="box-img-flex" href="https://twitter.com/MetazombiesVR" rel="noreferrer" target="_blank">
                                    <FaTwitter />
                                </a>
                            </li>
                            <li>
                                <a className="box-img-flex" href="https://discord.gg/U42hJR377q" rel="noreferrer" target="_blank">
                                    <FaDiscord />
                                </a>
                            </li>
                            <li>
                                <a className="box-img-flex" href="https://t.me/MetaZombies_VR" rel="noreferrer" target="_blank">
                                    <FaTelegramPlane />
                                </a>
                            </li>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FooterPage;
