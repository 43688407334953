import Banner from './slider/slider.jpg';

import Male from './characters/male.png';
import FeMale from './characters/female_main.png';
import Oculus from './oculusNew.png';

import Gun_1 from './gun/AK47_thumb.png';
import Gun_2 from './gun/SCAR_thumb.png';
import Gun_3 from './gun/M4A1_thumb.png';
import Gun_4 from './gun/HK416_thumb.png';
import Gun_5 from './gun/XM1014_thumb.png';
import Gun_6 from './gun/UZI_thumb.png';
import Gun_7 from './gun/VECTOR_thumb.png';
import Gun_8 from './gun/GLOCK_thumb.png';

import Akm_Gun from './gun/webp/AK47.webp';
import Scar_Gun from './gun/webp/SCAR.webp';
import M4A1_Gun from './gun/webp/M4A1.webp';
import HK416_Gun from './gun/webp/HK416.webp';
import XM1014_Gun from './gun/webp/XM1014.webp';
import Uzi_Gun from './gun/webp/UZI.webp';
import Vector_Gun from './gun/webp/VECTOR.webp';
import Glock_Gun from './gun/webp/GLOCK.webp';

import Gun_Slide_1 from './gun/AK47.png';
import Gun_Slide_2 from './gun/SCAR.png';
import Gun_Slide_3 from './gun/M4A1.png';
import Gun_Slide_4 from './gun/HK416.png';
import Gun_Slide_5 from './gun/XM1014.png';
import Gun_Slide_6 from './gun/UZI.png';
import Gun_Slide_7 from './gun/VECTOR.png';
import Gun_Slide_8 from './gun/GLOCK.png';

import Gun_Thumb_Webp_AKM from './gun/webp/AK47_thumb.webp';
import Gun_Thumb_Webp_SCAR from './gun/webp/SCAR_thumb.webp';
import Gun_Thumb_Webp_M4A1 from './gun/webp/M4A1_thumb.webp';
import Gun_Thumb_Webp_HK416 from './gun/webp/HK416_thumb.webp';
import Gun_Thumb_Webp_XM1014 from './gun/webp/XM1014_thumb.webp';
import Gun_Thumb_Webp_UZI from './gun/webp/UZI_thumb.webp';
import Gun_Thumb_Webp_VECTOR from './gun/webp/VECTOR_thumb.webp';
import Gun_Thumb_Webp_GLOCK from './gun/webp/GLOCK_thumb.webp';

// Items
import Drone_Webp from './items/webp/drone_slide.webp';
import Hangrao_Webp from './items/webp/hangrao_slide.webp';
import Hangrao_2_Webp from './items/webp/hangrao_slide1.webp';
import Trap_Webp from './items/webp/trap_slide.webp';
import Usung_Webp from './items/webp/usung_slide.webp';

// Items Thumb

import Drone_Thumb_Webp from './items/webp/drone.webp';
import Hangrao_Thumb_Webp from './items/webp/hangrao.webp';
import Hangrao_2_Thumb_Webp from './items/webp/hangrao.webp';
import Trap_Thumb_Webp from './items/webp/trap.webp';
import Usung_Thumb_Webp from './items/webp/usung.webp';


import Thumb from './thum.png';
import GunItemBg from './gun/gun-item-bg.svg';

import Gallery_Img1 from './gallery/1.webp';
import Gallery_Img2 from './gallery/2.webp';
import Gallery_Img3 from './gallery/3.webp';
import Gallery_Img4 from './gallery/4.webp';
import Gallery_Img5 from './gallery/5.webp';
import Gallery_Img6 from './gallery/6.webp';
import Gallery_Img7 from './gallery/7.webp';
import Gallery_Img8 from './gallery/8.webp';

import Background_1 from './backgrounds/bg-1.png';
import Background_2 from './backgrounds/banner-min-old.webp';
import Background_3 from './backgrounds/17-min.webp';
import Background_4 from './backgrounds/1-min.webp';
import Background_5 from './backgrounds/6-min.webp';
import Background_6 from './backgrounds/7-min.webp';
import Background_7 from './backgrounds/8-min.webp';
import Background_8 from './backgrounds/12-min.webp';
import Background_9 from './backgrounds/13-min.webp';
import Background_10 from './backgrounds/16-min.webp';
import Background_video from './backgrounds/bg-video.svg';
import Background_roadmap from './backgrounds/bg-roadmap.png';
import Background_title from './backgrounds/bg-title.png';
import Background_line from './backgrounds/bg-line.png';

import TechPartner_1 from './tech_partners/MetaMask.svg';
import TechPartner_2 from './tech_partners/bsc.svg';
import TechPartner_3 from './tech_partners/unity.svg';

// gun icons
import Percent from'./gun/icons/hp.svg';
import Percent_White from './gun/icons/hp-white.svg';
import Percent_Bg from'./gun/icons/hp_bg.svg';
import Damage from './gun/icons/Damage.png';
import Fire_rare from './gun/icons/Fire_rare.png';
import Recoil_control from './gun/icons/Recoil_control.png';
import Accurate from './gun/icons/Rare.png';
import Magazine_size from './gun/icons/Magazine_size.png';
import Force from './gun/icons/Force.png';
import Range from './gun/icons/Range.png';
import Shape_svg  from './shape/shape_svg.svg';
import Shape_svg_1  from './shape/shape_svg_1.svg';
import Shape_svg_2  from './shape/shape_svg_2.svg';
import Shape_svg_3  from './shape/shape_svg_3.svg';
import Shape_svg_4  from './shape/shape_svg_4.svg';
import Shape_svg_5  from './shape/shape_svg_5.svg';

import Pve from './game_play/pve-min.webp';
import Co_op from './game_play/coop-min.webp';
import Pvp from './game_play/pvp.png';

// Import Team
import CoreTeam_TienDung from './team/tiendung.jpg';
import CoreTeam_HuyTrinh from './team/huytrinh.jpg';
import CoreTeam_DucNguyen from './team/ducnguyen.jpg';
import CoreTeam_KhoaNguyen from './team/khoanguyen.jpg';
import CoreTeam_HuyLe from './team/huyle.jpg';
import CoreTeam_KhoaCao from './team/khoacao.jpg';
import CoreTeam_PhuongNguyen from './team/phuongle.jpg';
import CoreTeam_Nhile from './team/nhile.jpg';
import CoreTeam_KhanhNguyen from './team/khanhnguyen.jpg';
import CoreTeam_CamCo from './team/coha.jpg';
import CoreTeam_DuyNguyen from './team/duynguyen.jpg';
import CoreTeam_LoiLe from './team/loile.jpg';
import CoreTeam_HaoNguyen from './team/haonguyen.jpg';
// import Khung from './team/khung.png';
// Developer
import Developer_1 from './developer/logo-8k.png';
import Developer_2 from './developer/logobrayangstudio.png';

// NFTS

import Nft_1 from './nfts/NFT2-min.jpg';
import Nft_2 from './nfts/NFT5-min.jpg';
import Nft_3 from './nfts/NFT7-min.jpg';
import Nft_4 from './nfts/NFT8-min.jpg';
import Nft_5 from './nfts/NFT9-min.jpg';
import Nft_6 from './nfts/NFT10-min.jpg';
// Coin demo

import Coin from './coin.png';

// Comic

import Commic_1 from './comic/comic-1.jpg';
import Commic_2 from './comic/comic-2.jpg';
import Commic_3 from './comic/comic-3.jpg';
import Commic_4 from './comic/comic-4.jpg';
import Commic_5 from './comic/comic-5.jpg';
import Commic_6 from './comic/comic-6.jpg';
import Commic_7 from './comic/comic-7.jpg';
import Commic_8 from './comic/comic-8.jpg';
import Commic_9 from './comic/comic-9.jpg';

const nfts = [ Nft_1, Nft_2, Nft_3, Nft_4, Nft_5, Nft_6 ];
const nfts_market = [ Nft_1, Nft_2, Nft_3, Nft_4, Nft_5, Nft_6, Nft_1, Nft_2, Nft_3, Nft_4, Nft_5, Nft_6, Nft_1, Nft_2, Nft_3, Nft_4, Nft_5, Nft_6, Nft_1, Nft_2, Nft_3, Nft_4, Nft_5, Nft_6 ]
const banner = Banner;
const thumb = Thumb;
const oculus = Oculus;
const characters = [ Male, FeMale ];
const percent = [Percent, Percent_Bg, Percent_White];
const icons_gun = [Damage, Fire_rare , Recoil_control, Accurate, Magazine_size, Force, Range];
const gun = [
    Gun_1, 
    Gun_2, 
    Gun_3, 
    Gun_4, 
    Gun_5, 
    Gun_6, 
    Gun_7,
    Gun_8
];
const gun_webp = [
    Akm_Gun, 
    Scar_Gun, 
    M4A1_Gun, 
    HK416_Gun, 
    XM1014_Gun, 
    Uzi_Gun, 
    Vector_Gun, 
    Glock_Gun
];
const gun_thumb_webp = [
    Gun_Thumb_Webp_AKM, 
    Gun_Thumb_Webp_SCAR,
    Gun_Thumb_Webp_M4A1, 
    Gun_Thumb_Webp_HK416, 
    Gun_Thumb_Webp_XM1014, 
    Gun_Thumb_Webp_UZI,
    Gun_Thumb_Webp_VECTOR,
    Gun_Thumb_Webp_GLOCK
];
const gunSlide = [
    Gun_Slide_1, 
    Gun_Slide_2, 
    Gun_Slide_3, 
    Gun_Slide_4, 
    Gun_Slide_5, 
    Gun_Slide_6, 
    Gun_Slide_7,
    Gun_Slide_8
];

//Items
const items_webp = [
    Drone_Webp,
    Hangrao_Webp,
    Hangrao_2_Webp,
    Trap_Webp,
    Usung_Webp
];

const items_thumb_webp = [
    Drone_Thumb_Webp,
    Hangrao_Thumb_Webp,
    Hangrao_2_Thumb_Webp,
    Trap_Thumb_Webp,
    Usung_Thumb_Webp
];

const gallery = [
    Gallery_Img1, 
    Gallery_Img2, 
    Gallery_Img3, 
    Gallery_Img4, 
    Gallery_Img5, 
    Gallery_Img6, 
    Gallery_Img7, 
    Gallery_Img8
];

const teams_img = {
    CoreTeam_HuyLe: CoreTeam_HuyLe,
    CoreTeam_CamCo: CoreTeam_CamCo,
    CoreTeam_DuyNguyen: CoreTeam_DuyNguyen,
    CoreTeam_HaoNguyen: CoreTeam_HaoNguyen,
    CoreTeam_DucNguyen: CoreTeam_DucNguyen,
    CoreTeam_KhoaNguyen: CoreTeam_KhoaNguyen,
    CoreTeam_LoiLe: CoreTeam_LoiLe,
    CoreTeam_TienDung: CoreTeam_TienDung,
    CoreTeam_HuyTrinh: CoreTeam_HuyTrinh,
    CoreTeam_KhoaCao: CoreTeam_KhoaCao,
    CoreTeam_PhuongNguyen: CoreTeam_PhuongNguyen,
    CoreTeam_Nhile: CoreTeam_Nhile,
    CoreTeam_KhanhNguyen: CoreTeam_KhanhNguyen
};

const teams_core = [
    {
		img: teams_img.CoreTeam_CamCo,
		name: 'Clark Ha',
		office: 'CEO',
		linkedin: 'https://www.linkedin.com/in/c%C6%A1-h%C3%A0-007068216'
	},
    {
		img: teams_img.CoreTeam_HuyLe,
		name: 'Henry Le',
		office: 'Strategic Investor',
		linkedin: 'https://www.linkedin.com/in/huy-l%C3%AA-343134154'
	},
    {
		img: teams_img.CoreTeam_KhoaCao,
		name: 'Khoa Cao',
		office: 'Game Leader',
		linkedin: 'https://www.linkedin.com/in/khoa-cao-3ba6a478/'
	},
    {
		img: teams_img.CoreTeam_KhoaNguyen,
		name: 'Jaian',
		office: 'CMO',
		linkedin: 'https://www.linkedin.com/in/khoa-nguy%E1%BB%85n-37b259223'
	},
    {
		img: teams_img.CoreTeam_TienDung,
		name: 'Dmitriy Nagiev',
		office: 'Global Lead Marketing',
		linkedin: 'https://www.linkedin.com/in/edwarditmo07'
	},
    {
		img: teams_img.CoreTeam_DuyNguyen,
		name: 'Harvey Nguyen',
		office: 'Global Chief Marketing Officer (CMO)',
		linkedin: 'https://www.linkedin.com/in/duy-nguyen-033135121/'
	},
	// {
	// 	img: teams_img.CoreTeam_DucNguyen,
	// 	name: 'Jack Nguyen',
	// 	office: 'Chief Finance Officer (CFO)',
	// 	linkedin: 'https://www.linkedin.com/in/%C4%91%E1%BB%A9c-nguy%E1%BB%85n-5b74ba223/'
	// },
    {
		img: teams_img.CoreTeam_KhanhNguyen,
		name: 'Kim Song Il',
		office: 'Art Designer',
		linkedin: 'https://www.linkedin.com/in/khanh-nguyen-19a638224/'
	},
    // {
	// 	img: teams_img.CoreTeam_HuyTrinh,
	// 	name: 'Huy Trinh',
	// 	office: 'Designer',
	// 	linkedin: 'https://www.linkedin.com/in/trinh-huy-379083223/'
	// },
    {
		img: teams_img.CoreTeam_LoiLe,
		name: 'Scott Phan',
		office: 'Communication Specialist',
		linkedin: 'https://www.linkedin.com/in/scott-phan-b592b0234/'
	},
    {
		img: teams_img.CoreTeam_HaoNguyen,
		name: 'Hao Nguyen',
		office: 'Full-Stack Developer',
		linkedin: 'https://www.linkedin.com/in/nguyen-nhat-hao-05794a167'
	},
    {
		img: teams_img.CoreTeam_Nhile,
		name: 'Nhi Le',
		office: 'Game Developer',
		linkedin: 'https://www.linkedin.com/in/l%C3%AA-nhi-b62161236'
	},
    {
		img: teams_img.CoreTeam_PhuongNguyen,
		name: 'Phuong Nguyen',
		office: 'Game Developer',
		linkedin: 'https://www.linkedin.com/in/thanh-ph%C6%B0%C6%A1ng-nguy%E1%BB%85n-b5916b224/'
	}  
];

const developer = [Developer_1, Developer_2];
const gunItemBg = GunItemBg;
const backGrounds = [Background_1, Background_2, Background_3, Background_4 ,Background_5, Background_6, Background_7, Background_8, Background_9, Background_10];

const bg_video = Background_video;
const bg_roadmap = Background_roadmap;
const bg_title = Background_title;
const bg_line = Background_line;
const tech_partners = [TechPartner_1, TechPartner_2, TechPartner_3];
const shape_svg = [Shape_svg, Shape_svg_1, Shape_svg_2, Shape_svg_3, Shape_svg_4, Shape_svg_5];
const pve = Pve;
const coop = Co_op;
const pvp = Pvp;
const coin = Coin;
const comic_front = Commic_1;
const comic = [Commic_1, Commic_2, Commic_3, Commic_4, Commic_5, Commic_6, Commic_7, Commic_8, Commic_9];

export default banner;
export { characters };
export { gun };
export { thumb };
export { gallery };
export { gunSlide };
export { gunItemBg };
export { backGrounds };
export { gun_webp };
export { gun_thumb_webp };
export { oculus };
export { bg_video };
export { bg_roadmap };
export { bg_title };
export { bg_line };
export { items_webp };
export { items_thumb_webp };
export { tech_partners };
export { percent };
export { icons_gun };
export { shape_svg };
export { pve };
export { coop };
export { pvp };
export { teams_core };
export { developer };
export { nfts };
export { coin };
export { comic };
export { comic_front };
export { nfts_market };

