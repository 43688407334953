import React from "react";

import Socials from "../../components/Socials";

function Disclaimer() {
    return (
        <>
            <section className="disclaimer-us-page pt-120 pb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1 col-12">
                            <h1>Legal Disclaimer</h1>
                            <span>
                                The information provided on this website does not constitute investment advice, financial advice, trading advice, or any other sort of advice and you should not treat any of the website's content as such. The Metazombies VR team does not recommend that any cryptocurrency should be bought, sold, or held by you. Do conduct your own due diligence and consult your financial advisor before making any investment decisions. By purchasing Metazombies VR , you agree that you are not purchasing a security or investment and you agree to hold the team harmless and not liable for any losses or taxes you may incur. You also agree that the team is presenting the token "as is" and is not required to provide any support or services. You should have no expectation of any form from Metazombies VR and its team. Although Metazombies VR is an experimental token for social experiment and not a digital currency, the team strongly recommends that United States persons do not purchase it because the team cannot ensure compliance with United States regulations. Always make sure that you are in compliance with your local laws and regulations before you make any purchase.
                            </span>
                        </div>
                        <div className="col-12 pt-60">
                            <Socials />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Disclaimer;
