import React from "react";
import './loading.css';

const Loading = () => {

    return (
        <>
            <div className="lds"><div></div><div></div><div></div></div>
        </>
    );
}

export default Loading;
