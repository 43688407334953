import React, { useEffect, useState } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

import HeaderPage from "../components/Header/Header";
import FooterPage from "../components/Footer/Footer.js";
import PageGame from "./Game_Main";
import AboutPage from "./AboutUs/index";
import WorldOfMetazombies from "./WorldOfMeta/index";
import TermConditions from "./TermConditions";
import Disclaimer from "./Disclaimer";
import FaqPage from "./Faq/index";
import MintNft from "./Mint";
import PrivateMintNft from "./PrivateMint";
import MarketPage from "./MarketNfts";
import DetailNftFromMarket from "./MarketNfts/detail";
import Dashboard from "./Dashboard/Index";
import ShopPage from "./Shop/Index";
import DetailNftFromShop from "./Shop/Detail";
import Gallery from "./Gallery";

//web3
import { useWeb3React } from "@web3-react/core";
import { connectors } from "../utils/connectors";

import { ToastProvider } from 'react-toast-notifications';




function IndexPage() {

    const [activeTab, setActiveTab] = useState("");

    const {
        library,
        chainId,
        account,
        activate,
        deactivate,
        active
    } = useWeb3React();

    const [signature, setSignature] = useState("");
    const [error, setError] = useState("");
    const [network, setNetwork] = useState(undefined);
    const [message, setMessage] = useState("");
    const [signedMessage, setSignedMessage] = useState("");
    const [verified, setVerified] = useState();

    const refreshState = () => {
        window.localStorage.setItem("provider", undefined);
        window.localStorage.setItem("account", false);
        setNetwork("");
        setMessage("");
        setSignature("");
        setVerified(undefined);
    };

    const disconnect = () => {
        refreshState();
        deactivate(); 
    };

    useEffect(() => {
        const provider = window.localStorage.getItem("provider");
        activate(connectors[provider]);
    }, []);


    return (
        <Router>
            <ToastProvider
                placement="bottom-left"
            >
                <HeaderPage 
                    disconnect={() => disconnect()} 
                    account={account} 
                    chainId={chainId} 
                    library={library}
                />
                <div className="main-wrapper">
                    <Switch>
                        <Route exact path="/">
                            <PageGame />
                        </Route>
                        <Route exact path="/about-us">
                            <AboutPage />
                        </Route>
                        <Route exact path="/world-of-metazombies-vr">
                            <WorldOfMetazombies />
                        </Route>
                        <Route exact path="/term-and-conditions">
                            <TermConditions />
                        </Route>
                        <Route exact path="/faq">
                            <FaqPage />
                        </Route>
                        <Route exact path="/disclaimer">
                            <Disclaimer />
                        </Route>
                        <Route exact path="/dashboard">
                            <Dashboard
                                activeTab={activeTab}
                                account={account}
                            />
                        </Route>
                        <Route exact path="/mint-nft">
                            <MintNft
                                disconnect={() => disconnect()} 
                                account={account} 
                                chainId={chainId} 
                                library={library}
                            />
                        </Route>
                        <Route exact path="/private-mint-nft">
                            <PrivateMintNft
                                disconnect={() => disconnect()} 
                                account={account} 
                                chainId={chainId} 
                                library={library}
                            />
                        </Route>
                        <Route exact path="/market-nft">
                            <MarketPage />
                        </Route>
                        <Route exact path="/detail-nft">
                            <DetailNftFromMarket />
                        </Route>
                        <Route exact path="/shop">
                            <ShopPage />
                        </Route>
                        <Route exact path="/shop-nft">
                            <DetailNftFromShop />
                        </Route>
                        <Route exact path="/gallery">
                            <Gallery />
                        </Route>
                    </Switch>
                </div>
                <FooterPage />
            </ToastProvider>
        </Router>
    );
}

export default IndexPage;
