import React, { useState, useEffect } from "react";
import axios from 'axios';

import {
    Link
} from "react-router-dom";

import PageTitle from "../../components/Page_Title/Page_Title";

import Loading from "../../components/Loading";
import { nfts_market } from "../../assets/images/index.js";

import { 
    FiFilter, 
    FiRefreshCw,
    FiX 
} from "react-icons/fi";

const MarketPage = () => {
    let currentOffset = 0;
    const[showFilters, checkShowFilters] = useState(false);
    const[loading, setLoading] = useState(false);
    const[listNft, setListNft] = useState([]);

    const loadNfts = () => {
        const newListNfts = [];
        axios
          .get(`https://pokeapi.co/api/v2/pokemon?limit=30&offset=${currentOffset}`)
          .then(({ data }) => {
              setLoading(false);
            data.results.forEach((p) => newListNfts.push(p.name));
            setListNft((nft) => [...nft, ...newListNfts]);
          }).catch(error => console.log(error));
        currentOffset += 30;
    };

    const handleScroll = (e) => {
        const scrollHeight = e.target.documentElement.scrollHeight;
        const currentHeight = Math.ceil(
            e.target.documentElement.scrollTop + window.innerHeight
        );
        if (currentHeight + 1 >= scrollHeight) {
            setLoading(true);
            loadNfts();
        }
    }
    useEffect( () => {
        loadNfts();
        window.addEventListener('scroll', handleScroll)
    },[])
    

    return (
        <>
            <PageTitle
                title="GALLERY NFT"
                desc="Get your ZOMBIES NFT now"
            />
            <section className="pt-90 pb-90 market-page">
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-md-6 col-12">
                            <h3 className="title ">Explore Nfts</h3>
                        </div>
                        <div className="col-md-6 col-12 btn-right">
                            <button className={!showFilters ? "discover-filter-button btn" : "close-filter discover-filter-button btn"} onClick={() => {checkShowFilters(!showFilters)}}>Filter { !showFilters ? <FiFilter /> : <FiX />}</button>
                        </div>
                    </div>
                    <div className={!showFilters ? "filter-wrap" : "filter-wrap box-maxHeight"} style={{
                                overflow: 'hidden',
                                maxHeight : "0",
                                transition: "700ms ease-in",
                                animationDelay: "0ms",
                                marginBottom: "30px"
                            }}>
                        <div className="row mb-5"
                            style={{
                                transition: "700ms ease-in",
                                animationDelay: "0ms"
                            }}
                        >
                                <div className="col-12">
                                    <div className="filter-wrapper filter-exp-expand">
                                        <div className="filter-select-option">
                                            <label className="filter-label">Background</label>
                                            <select>
                                                <option data-display="Most liked">Most liked</option>
                                                <option value="1">Least liked</option>
                                            </select>
                                        </div>
                                        <div className="filter-select-option">
                                            <label className="filter-label">Clothers</label>
                                            <select>
                                                <option data-display="Category">Category</option>
                                                <option value="1">Art</option>
                                                <option value="1">Photograph</option>
                                                <option value="2">Metaverses</option>
                                                <option value="4">Potato</option>
                                                <option value="4">Photos</option>
                                            </select>
                                        </div>
                                        <div className="filter-select-option">
                                            <label className="filter-label">Earring</label>
                                            <select>
                                                <option data-display="Collections">Collections</option>
                                                <option value="1">BoredApeYachtClub</option>
                                                <option value="2">MutantApeYachtClub</option>
                                                <option value="4">Art Blocks Factory</option>
                                            </select>
                                        </div>
                                        <div className="filter-select-option">
                                            <label className="filter-label">Eyes</label>
                                            <select>
                                                <option data-display="Sale type">Sale type</option>
                                                <option value="1">Fixed price</option>
                                                <option value="2">Timed auction</option>
                                                <option value="4">Not for sale</option>
                                                <option value="4">Open for offers</option>
                                            </select>
                                        </div>
                                        <div className="filter-select-option">
                                            <label className="filter-label">Fur</label>
                                            <select>
                                                <option data-display="Sale type">Sale type</option>
                                                <option value="1">Fixed price</option>
                                                <option value="2">Timed auction</option>
                                                <option value="4">Not for sale</option>
                                                <option value="4">Open for offers</option>
                                            </select>
                                        </div>
                                        <div className="filter-select-option">
                                            <label className="filter-label">Hat</label>
                                            <select>
                                                <option data-display="Sale type">Sale type</option>
                                                <option value="1">Fixed price</option>
                                                <option value="2">Timed auction</option>
                                                <option value="4">Not for sale</option>
                                                <option value="4">Open for offers</option>
                                            </select>
                                        </div>
                                        <div className="filter-select-option">
                                            <label className="filter-label">Mouth</label>
                                            <select>
                                                <option data-display="Sale type">Sale type</option>
                                                <option value="1">Fixed price</option>
                                                <option value="2">Timed auction</option>
                                                <option value="4">Not for sale</option>
                                                <option value="4">Open for offers</option>
                                            </select>
                                        </div>
                                        <div className="filter-select-option">
                                            <label className="filter-label">By id</label>
                                            <select>
                                                <option data-display="Sale type">Sale type</option>
                                                <option value="1">Fixed price</option>
                                                <option value="2">Timed auction</option>
                                                <option value="4">Not for sale</option>
                                                <option value="4">Open for offers</option>
                                            </select>
                                        </div>
                                    </div>
                                    <button className="reset-filter-button btn" >Reset Filters <FiRefreshCw /> </button>
                                </div>
                            </div> 
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="market-list">
                                {
                                    nfts_market.map((item, index) => {
                                        return (
                                            <div className="nft-style-item" key={index}>
                                                <div className="card-thumbnail">
                                                    <Link to="/detail-nft">
                                                        <img src={item} alt="NFT_portfolio" />
                                                    </Link>
                                                </div>
                                                <div className="card-content">
                                                    <Link to="/detail-nft"><span className="product-name">Zombies #{index}</span></Link>
                                                    <div className="bid-react-area">
                                                        <div className="last-bid">0.244wETH</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                                {
                                    listNft.map((item, index) => {
                                        return (
                                            <div className="nft-style-item" key={index}>
                                                <div className="card-thumbnail">
                                                    <a href="!#">
                                                        <img src={item} alt="NFT_portfolio" />
                                                    </a>
                                                </div>
                                                <div className="card-content">
                                                    <a href="!#"><span className="product-name">Zombies #{index}</span></a>
                                                    <div className="bid-react-area">
                                                        <div className="last-bid">0.244wETH</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="loading-wrap">
                                {
                                    !loading ? <Loading /> : ''
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default MarketPage;
