import React, { useState, useRef, useEffect, useCallback } from "react";

import { useWeb3React } from "@web3-react/core";

import { ToastProvider, useToasts } from 'react-toast-notifications';

import { FiCopy, FiCornerDownRight, FiCornerUpLeft } from "react-icons/fi";

const MemberProgram = () => {

    const {
        library,
        activate,
        active,
        chainId,
        account
    } = useWeb3React();

    const [dataMember, setDataMember] = useState([]);
    const [parentMember, setParentMember] = useState([]);
    const [backParentMember, setBackParentMember] = useState(false);

    const ReferralCopyToClipBoard = () => {
        const { addToast } = useToasts();

        const copyToClipBoard = async copyMe => {
            try {
                await navigator.clipboard.writeText(copyMe);
                addToast('Copied Successfully', {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 2000
                })
            } catch (err) {
                addToast('Failed to copy!', {
                    appearance: 'error',
                    autoDismiss: true,
                    autoDismissTimeout: 2000
                })
            }
        };
        return (
            <FiCopy className="copy" onClick={() => copyToClipBoard("https://flashstriker.com/affiliates?ref=" + account)} />
        )
    };

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="mtz-profile_title text-white text-left mt-0">
                            Member Program
                        </div>
                        <hr />
                    </div>
                    <div className="col-lg-12 col-12">
                        <div className="referral-box-info">
                            <span className="referral-box-title">Get Your Link Referral</span>
                            <div className="name">
                                <span className="referral-box-info-address">{'https://metazombies-vr.com/affiliates?ref=' + account}</span>
                                <ToastProvider
                                    placement="bottom-left"
                                >
                                    <ReferralCopyToClipBoard />
                                </ToastProvider>
                            </div>
                        </div>
                        <div className="rule-wrap mt-3">
                            <span className="mtz-profile_title text-white referral-box-title text-left">Affiliate Nft Program</span>
                            <table className="table table-bordered text-white">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Referral</th>
                                        <th scope="col">Reward</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>TIE1</td>
                                        <td>5%</td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>TIE2</td>
                                        <td>3%</td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>TIE3</td>
                                        <td>2%</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="rule-wrap mt-3">
                            <span className="mtz-profile_title text-white referral-box-title text-left">Referral Staking Program</span>
                            <table className="table table-bordered text-white">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Referral</th>
                                        <th scope="col">Reward</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>TIE1</td>
                                        <td>7%</td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>TIE2</td>
                                        <td>4%</td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>TIE3</td>
                                        <td>2%</td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>TIE4</td>
                                        <td>1%</td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td>TIE5</td>
                                        <td>1%</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MemberProgram;
