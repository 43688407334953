import React from "react";

import PageTitle from "../../components/Page_Title/Page_Title";
import { backGrounds } from "../../assets/images/index.js";

function Gallery() {
    return (
        <>
            <PageTitle 
                title="Gallery"
                breadcrumb="Gallery"
                background={backGrounds[2]}
            />
            <section className="about-us-page pt-60 pb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="d-block mb-4">About Metazombies VR</h1>
                            <div className="video-list">
                                <div className="video-item">
                                    <div className="video-top">
                                    <iframe width="100%" height="200" src="https://www.youtube.com/embed/vXxazfbj_to" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>                                    </div>
                                    <div className="video-bottom">
                                        <h3>MetaZombiesVR Trailer 2</h3>
                                    </div>
                                </div>
                                <div className="video-item">
                                    <div className="video-top">
                                        <iframe width="100%" height="200" src="https://www.youtube.com/embed/wN8CzNUVHL4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>                                    </div>
                                    <div className="video-bottom">
                                        <h3>MetazombiesVR Co op</h3>
                                    </div>
                                </div>
                                <div className="video-item">
                                    <div className="video-top">
                                    <iframe width="100%" height="200" src="https://www.youtube.com/embed/NFHdy2IrKwg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>                                    </div>
                                    <div className="video-bottom">
                                        <h3>MetazombiesVR Demo</h3>
                                    </div>
                                </div>
                                <div className="video-item">
                                    <div className="video-top">
                                    <iframe width="100%" height="200" src="https://www.youtube.com/embed/lFtLq8jadZU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>                                    </div>
                                    <div className="video-bottom">
                                        <h3>MetazombiesVR Gameplay Demo l Oculus VR Version</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Gallery;
