import React, { useState, useEffect } from "react";

import { FiArrowDown, FiSend, FiToggleRight } from "react-icons/fi";

import { coin } from "../../assets/images/index.js";
import token_busd from '../../assets/images/busd.png';

const Swap = () => {

    const Initcurrency = {
        decimals: 18,
        name: 'BUSD',
        img: token_busd,
        balance: 0
    }

    const InitOtherCurrency = {
        decimals: 18,
        name: 'MZB',
        img: coin,
        balance: 0
    }

    const [currencyToken, setCurrencyToken] = useState(Initcurrency);
    const [otherCurrencyToken, setOtherCurrencyToken] = useState(InitOtherCurrency);
    const [amount1, setAmount1] = useState(0);
    const [amount2, setAmount2] = useState(0);

    const [priceMZB, setPriceMZB] = useState(0);
    const [isEnableBUSD, setIsEnableBUSD] = useState(false);
    const [isEnableMZB, setIsEnableMZB] = useState(false);

    const [listHistory, setListHistory] = useState([]);

    const handleSwitchBalance = () => {
        if (currencyToken.name === 'BUSD') {
            setCurrencyToken(InitOtherCurrency);
            setOtherCurrencyToken(Initcurrency);
            setAmount1(0);
            setAmount2(0);
        } else {
            setCurrencyToken(Initcurrency);
            setOtherCurrencyToken(InitOtherCurrency);
            setAmount1(0);
            setAmount2(0);
        }
    }

    const checkEnableBUSD = () => {
        setIsEnableBUSD(true);
    }

    const checkEnableMZB = () => {
        setIsEnableMZB(true);
    }

    return (
        <>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="mtz-profile_title text-white text-left mt-0">
                            Exchange
                        </div>
                        <hr />
                    </div>
                    <div className="col-lg-7 col-12">
                        <div className="d-flex exchange-wrap">
                            <div className="exchange-item earning">
                                <span>MZB Price</span>
                                <div className="exchange-icon">
                                    <div><span>1</span><img src={coin} alt="" /></div>
                                    <span> = </span>
                                    <div><span>{priceMZB}</span><img src={token_busd} alt="" /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 col-12">
                        <div className="wallet-box portfolio-box swap-wrap">
                            <div className="info_name">
                                <h2 className="text-white">Exchange</h2>
                                <span className="text-white">Trade tokens in an instant</span>
                                <div className="swap-input-wrap">
                                    <div className="swap-input-container">
                                        <input placeholder="Amount" className="amout form-control" type="number" />
                                        <button className="btn rotated-btn">MAX</button>
                                        <div className="swap-input-icon">
                                            <img className="fst-icon" src={currencyToken.img} alt="" />
                                            <span>{currencyToken.name}</span>
                                        </div>
                                    </div>
                                </div>
                                <FiArrowDown className="swap-icon" onClick={() => handleSwitchBalance()} />
                                <div className="swap-input-wrap">
                                    <div className="swap-input-container">
                                        <input placeholder="Amount" className="amout form-control" type="number"/>
                                        <div className="swap-input-icon">
                                            <img className="fst-icon" src={otherCurrencyToken.img} alt="" />
                                            <span>{otherCurrencyToken.name}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex enable-wrap">
                                    {
                                        !isEnableBUSD ?
                                            <button className="btn rotated-btn" onClick={checkEnableBUSD}>
                                                <div className="mtz-btn-wrap">
                                                    <FiToggleRight /> Enable BUSD
                                                </div>
                                            </button>
                                            :
                                            null
                                    }
                                    {
                                        !isEnableMZB ?
                                            <button className="btn rotated-btn" onClick={checkEnableMZB}>
                                                <div className="mtz-btn-wrap">
                                                    <FiToggleRight /> Enable MZB
                                                </div>
                                            </button>
                                            :
                                            null
                                    }
                                </div>
                                {
                                    isEnableBUSD && isEnableMZB ?
                                        <button className="btn rotated-btn">
                                            <div className="mtz-btn-wrap">
                                                <FiSend /> Swap
                                            </div>
                                        </button>
                                        :
                                        null
                                }

                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt-5">
                        <div className="mtz-profile_title text-white text-left mt-0">
                            History Swap
                        </div>
                        <hr />
                    </div>
                    <div className="col-lg-12 col-12">
                        <div className="table-responsive portfolio-table-wrap table-modal mt-3">
                            <table className="table table-bordered text-white">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">From</th>
                                        <th scope="col">To</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Amount Receive</th>
                                        <th scope="col">Fee</th>
                                        <th scope="col">Created Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Swap;
