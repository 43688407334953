import React, { useState, useRef, useEffect } from "react";

import { nfts_market } from "../../assets/images/index.js";


const MyNft = ({ account, chainId, library }) => {

    const [currentItems, setCurrentItems] = useState([]);
    const [filterItem, setFilterItem] = useState([]);
    const [filterType, setFilterType] = useState("");

    const handleInputChange = (e) => {
        setFilterType(e.target.value);
    }

    const filterByType = (filteredData) => {
        // Avoid filter for empty string
        if (!filterType) {
            return filteredData;
        }

        const filteredNfts = filteredData.filter(
            (item) => item.type === filterType
        );
        return filteredNfts;
    };

    useEffect(() => {
        var filteredData = filterByType(filterItem);
        setCurrentItems(filteredData);
    }, [filterType]);

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="d-flex justify-content-between align-items-center fs-profile_title-wrap">
                            <div className="fs-profile_title text-white text-left mt-0">
                                NFT ({currentItems.length})
                            </div>
                            <div className="head-filter d-flex justify-content-between align-items-center mb-2">
                                <div className="filter-select-option">
                                    <select onChange={handleInputChange}>
                                        <option data-display="Sale type">Choose Type</option>
                                        <option value="1">Player</option>
                                        <option value="2">Trap</option>
                                    </select>
                                </div>
                                <div className="filter-select-option">
                                    <select>
                                        <option data-display="Sale type">Filter</option>
                                        <option value="1">Playing</option>
                                        <option value="2">Selling</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <hr />
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="mynft-list">
                        {
                            nfts_market.map((item, index) => {
                                return (
                                    <div className="nft-style-item" key={index}>
                                        <div className="card-thumbnail">
                                            <a href="!#">
                                                <img src={item} alt="NFT_portfolio" />
                                            </a>
                                        </div>
                                        <div className="card-content">
                                            <a href="!#"><span className="product-name">Zombies #{index}</span></a>
                                            <div className="bid-react-area">
                                                <div className="last-bid">0.244wETH</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default MyNft;
