import React, { useState } from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import Logo from '../assets/images/logo.png';
import ReactImageVideoLightbox from "react-image-video-lightbox";
import { Swiper, SwiperSlide } from 'swiper/react';
import HTMLFlipBook from "react-pageflip";
import YouTube from 'react-youtube';

import 'animate.css';
import Fancybox from "./fancybox.js";


import { FiPlayCircle, FiLinkedin, FiAlignJustify, FiX, FiBook } from "react-icons/fi";
import { FaTwitter, FaDiscord, FaTelegramPlane, FaYoutube } from "react-icons/fa";
import { MdOutlineArrowForwardIos, MdOutlineArrowBackIos } from "react-icons/md";


// import Fancybox from "./fancybox.js";

import '../assets/css/style.css';
import '../assets/css/responsive.css';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/thumbs/thumbs.min.css';
// import 'swiper/css';
// import "swiper/css/pagination";
// import "swiper/css/thumbs";
// import "swiper/css/navigation";
import video from '../assets/images/demo.mp4';
import thumb_video_full from '../assets/images/thum.jpg';
import { characters } from "../assets/images/index.js";
import { gun } from "../assets/images/index.js";
import { gallery } from "../assets/images/index.js";
import { gunSlide } from "../assets/images/index.js";
import { shape_svg } from "../assets/images/index.js";
import { backGrounds } from "../assets/images/index.js";
import { gun_webp } from "../assets/images/index.js";
import { gun_thumb_webp } from "../assets/images/index.js";
import { bg_video } from "../assets/images/index.js";
import { bg_title } from "../assets/images/index.js";
import { bg_line } from "../assets/images/index.js";
import { bg_roadmap } from "../assets/images/index.js";
import { items_thumb_webp } from "../assets/images/index.js";
import { items_webp } from "../assets/images/index.js";
import { tech_partners } from "../assets/images/index.js";
import { teams_core } from "../assets/images/index";
import { developer } from "../assets/images/index.js";
import { percent } from "../assets/images/index.js";
import { icons_gun } from "../assets/images/index.js";
import { pve } from "../assets/images/index.js";
import { coop } from "../assets/images/index.js";
import { pvp } from "../assets/images/index.js";
import { oculus } from "../assets/images/index.js";
import { coin } from "../assets/images/index.js";
import { comic } from "../assets/images/index.js";
// import Video from '../assets/images/video.mp4';
import logo_mrs from '../assets/images/logo-mrs.png';


import SwiperCore, { Pagination, Navigation, Thumbs } from 'swiper';
SwiperCore.use([Pagination, Navigation, Thumbs]);

let guns = [
	{
		name: "AK47",
		type: "Assault Rifle",
		thumb: gun[0],
		nft: gunSlide[0],
		nft_webp: gun_webp[0],
		thumb_webp: gun_thumb_webp[0],
		damage: {
			number: 10,
			icon: icons_gun[0],
			percent_cal: 10
		},
		fire_rare: {
			number: 0.2,
			icon: icons_gun[1],
			percent_cal: 20
		},
		recoil_control: {
			number: 20,
			icon: icons_gun[2],
			percent_cal: 20
		},
		accurate: {
			number: 60,
			icon: icons_gun[3],
			percent_cal: 60
		},
		magazine_size: {
			number: 30,
			icon: icons_gun[4],
			percent_cal: 30
		},
		force: {
			number: 100,
			icon: icons_gun[5],
			percent_cal: 50
		},
		range: {
			number: 50,
			icon: icons_gun[6],
			percent_cal: 50
		}
	},
	{
		name: "Scar-L",
		type: "Assault Rifle",
		thumb: gun[1],
		nft: gunSlide[1],
		nft_webp: gun_webp[1],
		thumb_webp: gun_thumb_webp[1],
		damage: {
			number: 6,
			icon: icons_gun[0],
			percent_cal: 6
		},
		fire_rare: {
			number: 0.2,
			icon: icons_gun[1],
			percent_cal: 20
		},
		recoil_control: {
			number: 15,
			icon: icons_gun[2],
			percent_cal: 15
		},
		accurate: {
			number: 70,
			icon: icons_gun[3],
			percent_cal: 70
		},
		magazine_size: {
			number: 25,
			icon: icons_gun[4],
			percent_cal: 25
		},
		force: {
			number: 70,
			icon: icons_gun[5],
			percent_cal: 35
		},
		range: {
			number: 50,
			icon: icons_gun[6],
			percent_cal: 50
		}
	},
	{
		name: "M416",
		type: "Assault Rifle",
		thumb: gun[2],
		nft: gunSlide[2],
		nft_webp: gun_webp[2],
		thumb_webp: gun_thumb_webp[2],
		damage: {
			number: 5,
			icon: icons_gun[0],
			percent_cal: 5
		},
		fire_rare: {
			number: 0.15,
			icon: icons_gun[1],
			percent_cal: 15
		},
		recoil_control: {
			number: 17,
			icon: icons_gun[2],
			percent_cal: 17
		},
		accurate: {
			number: 70,
			icon: icons_gun[3],
			percent_cal: 70
		},
		magazine_size: {
			number: 25,
			icon: icons_gun[4],
			percent_cal: 25
		},
		force: {
			number: 70,
			icon: icons_gun[5],
			percent_cal: 35
		},
		range: {
			number: 50,
			icon: icons_gun[6],
			percent_cal: 50
		}
	},
	{
		name: "HK416",
		type: "Assault Rifle",
		thumb: gun[3],
		nft: gunSlide[3],
		nft_webp: gun_webp[3],
		thumb_webp: gun_thumb_webp[3],
		damage: {
			number: 7,
			icon: icons_gun[0],
			percent_cal: 7
		},
		fire_rare: {
			number: 0.15,
			icon: icons_gun[1],
			percent_cal: 15
		},
		recoil_control: {
			number: 14,
			icon: icons_gun[2],
			percent_cal: 14
		},
		accurate: {
			number: 80,
			icon: icons_gun[3],
			percent_cal: 80
		},
		magazine_size: {
			number: 35,
			icon: icons_gun[4],
			percent_cal: 35
		},
		force: {
			number: 60,
			icon: icons_gun[5],
			percent_cal: 30
		},
		range: {
			number: 50,
			icon: icons_gun[6],
			percent_cal: 50
		}
	},
	{
		name: "XM1014",
		type: "ShotGun",
		thumb: gun[4],
		nft: gunSlide[4],
		nft_webp: gun_webp[4],
		thumb_webp: gun_thumb_webp[4],
		damage: {
			number: 12,
			icon: icons_gun[0],
			percent_cal: 12
		},
		fire_rare: {
			number: 1,
			icon: icons_gun[1],
			percent_cal: 100
		},
		recoil_control: {
			number: 30,
			icon: icons_gun[2],
			percent_cal: 30
		},
		accurate: {
			number: 50,
			icon: icons_gun[3],
			percent_cal: 50
		},
		magazine_size: {
			number: 5,
			icon: icons_gun[4],
			percent_cal: 5
		},
		force: {
			number: 120,
			icon: icons_gun[5],
			percent_cal: 60
		},
		range: {
			number: 30,
			icon: icons_gun[6],
			percent_cal: 30
		}
	},
	{
		name: "Uzi",
		type: "Automatic Gun",
		thumb: gun[5],
		nft: gunSlide[5],
		nft_webp: gun_webp[5],
		thumb_webp: gun_thumb_webp[5],
		damage: {
			number: 5,
			icon: icons_gun[0],
			percent_cal: 5
		},
		fire_rare: {
			number: 0.1,
			icon: icons_gun[1],
			percent_cal: 10
		},
		recoil_control: {
			number: 12,
			icon: icons_gun[2],
			percent_cal: 12
		},
		accurate: {
			number: 75,
			icon: icons_gun[3],
			percent_cal: 75
		},
		magazine_size: {
			number: 40,
			icon: icons_gun[4],
			percent_cal: 40
		},
		force: {
			number: 40,
			icon: icons_gun[5],
			percent_cal: 20
		},
		range: {
			number: 35,
			icon: icons_gun[6],
			percent_cal: 35
		}
	},
	{
		name: "Vector",
		type: "Automatic Gun",
		thumb: gun[6],
		nft: gunSlide[6],
		nft_webp: gun_webp[6],
		thumb_webp: gun_thumb_webp[6],
		damage: {
			number: 6,
			icon: icons_gun[0],
			percent_cal: 6
		},
		fire_rare: {
			number: 0.1,
			icon: icons_gun[1],
			percent_cal: 10
		},
		recoil_control: {
			number: 10,
			icon: icons_gun[2],
			percent_cal: 10
		},
		accurate: {
			number: 70,
			icon: icons_gun[3],
			percent_cal: 70
		},
		magazine_size: {
			number: 50,
			icon: icons_gun[4],
			percent_cal: 50
		},
		force: {
			number: 50,
			icon: icons_gun[5],
			percent_cal: 25
		},
		range: {
			number: 35,
			icon: icons_gun[6],
			percent_cal: 35
		}
	},
	{
		name: "Glock",
		type: "Hand Gun",
		thumb: gun[7],
		nft: gunSlide[7],
		nft_webp: gun_webp[7],
		thumb_webp: gun_thumb_webp[7],
		damage: {
			number: 3,
			icon: icons_gun[0],
			percent_cal: 3
		},
		fire_rare: {
			number: 0.2,
			icon: icons_gun[1],
			percent_cal: 20
		},
		recoil_control: {
			number: 7,
			icon: icons_gun[2],
			percent_cal: 7
		},
		accurate: {
			number: 80,
			icon: icons_gun[3],
			percent_cal: 80
		},
		magazine_size: {
			number: 10,
			icon: icons_gun[4],
			percent_cal: 10
		},
		force: {
			number: 50,
			icon: icons_gun[5],
			percent_cal: 25
		},
		range: {
			number: 15,
			icon: icons_gun[6],
			percent_cal: 15
		}
	}
]

let items = [
	{
		name: "Drone",
		thumb: items_thumb_webp[0],
		nft: items_webp[0],
		nft_webp: items_webp[0],
		thumb_webp: items_thumb_webp[0],
	},
	{
		name: "Iron Fence",
		thumb: items_thumb_webp[1],
		nft: items_webp[1],
		nft_webp: items_webp[1],
		thumb_webp: items_thumb_webp[1]
	},
	{
		name: "Iron Fence",
		thumb: items_thumb_webp[2],
		nft: items_webp[2],
		nft_webp: items_webp[2],
		thumb_webp: items_thumb_webp[2]
	},
	{
		name: "Trap",
		thumb: items_thumb_webp[3],
		nft: items_webp[3],
		nft_webp: items_webp[3],
		thumb_webp: items_thumb_webp[3]
	},
	{
		name: "Gun Emplacement",
		thumb: items_thumb_webp[4],
		nft: items_webp[4],
		nft_webp: items_webp[4],
		thumb_webp: items_thumb_webp[4]
	}
]

let characters_content = [
	{
		name: 'TOM',
		content: `An elite soldier who was trained for years in DELTA force which is one of Special Mission Units and also the no.1 counterterrorism force of the U.S.
		Troops in this force are physically and mentally trained for years with survival skills under extremely harsh conditions so that they can accomplish their missions.
		Tom has excellently accomplished missions that seem to be almost impossible for normal soldiers: counterterrorism, hostage rescue, direct attack and special reconnaissance, etc. With the ability to use various kinds of weapons competently and special training, Tom can eliminate zombies in a perfect way.		
		`,
		img: characters[0]
	},
	{
		name: 'Taylor',
		content: `An excellent SWAT member who used to have a happy family until her husband and son were attacked and infected by zombies at her house while she was on duty. After the transformation of her beloved ones, the young mother’s heart was filled with hatred for zombies and she decided to fight against them and try to survive in this dark time.`,
		img: characters[1]
	}
]

const Page = React.forwardRef((props, ref) => {
    return (
        <div className="page" ref={ref}>
            <div className="page-comic-content">
                <div className="page-comic-image"></div>
                <div className="page-comic-text">{props.children}</div>
                {/* <div className="page-comic-numbers">{props.number}</div> */}
            </div>
        </div>
    );
});

const PageCover = React.forwardRef((props, ref) => {
    return (
        <div className="page page-cover" ref={ref} data-density="hard">
            <div className="page-content">
                {props.children}
            </div>
        </div>
    );
});

function PageGame() {

	const opts = {
		height: '390',
      	width: '640',
		playerVars: {
			autoplay: 1,
			rel: 0,
			showinfo: 0,
			loop: 1
		},
	};

	const [openVideo, setOpenVideo] = useState(false);

	const [lightboxOpen, setlightboxOpen] = useState(false);
	const [thumbsSwiper, setThumbsSwiper] = useState(null);

	const pagination = {
		clickable: true,
		renderBullet: function (index, className) {
			return '<span class="' + className + ' character-bullet ">' + (index + 1) + "</span>";
		},
	};

	const pagination_features = {
		clickable: true,
		renderBullet: function (index, className) {
			return '<span class="' + className + ' features-bullet ">' + (index + 1) + "</span>";
		},
	};


    const flipbook = React.useRef(null);

    const flipBack = React.useCallback(() => {
        const pageFlipObj = flipbook.current.pageFlip();
        if (pageFlipObj?.getCurrentPageIndex() === 0) {
            pageFlipObj.flip(pageFlipObj?.getPageCount() - 1);
        } else {
            pageFlipObj.flipPrev();
        }
    }, [flipbook]);

    const flipForward = React.useCallback(() => {
        const pageFlipObj = flipbook.current.pageFlip();
        if (
            pageFlipObj?.getCurrentPageIndex() + 2 ===
            pageFlipObj?.getPageCount()
        ) {
            pageFlipObj.flip(0);
        } else {
            pageFlipObj.flipNext();
        }
    }, [flipbook]);

	return (
		<>
			<main className="main-wrapper">
				<section className="slider-area slider-bg">
					<div className="banner-position">
						<video id="background-video" autoPlay loop muted>
							<source src={video} type="video/mp4" />
							<source src={video} type="video/ogg" />
						</video>
						{/* <LazyLoadImage
							loading="lazy"
							alt="Metazombies VR"
							src={backGrounds[1]}
							effect="blur"
						/> */}
						{/* <Image
							src={backGrounds[1]}
							webp={backGrounds[1]}
						/> */}
					</div>
					<div className="slider-content text-center">
						{/* <img className="slider-content-img" src={oculus} alt="" /> */}
						<h2 className="tlt">
							Metazombies
							<span> VR</span>
						</h2>
						<p className="">
							MetaZombies VR is a survival game which comprises extreme shooting actions, post-apocalyptic horror elements in combination with blockchain technology. Events in-game happen during the pandemic crisis in the future which are designed based on Virtual Reality (VR) technology and played by Oculus Quest 2 equipment promising to bring players the most lively fighting experience via the VR glasses. Players can travel in a large open world with arms, use their skills to survive, collect modern weapons to eliminate zombie legions, and stay alive as long as they can.
						</p>
						<a href="https://sidequestvr.com/app/11818/metazombiesvr" className="btn btn-play-demo" target="_blank"><img className="slider-content-img" src={oculus} alt="" /> <span>Play game demo</span></a>
					</div>
				</section>
				<section id="about-us" className="gamestory-wrap third-about-bg pt-120 pb-90">
					{/* <div className="slider-circle-shape">
						<img src={shape_svg[0]} alt="" className="rotateme" />
						<LazyLoadImage
							loading="lazy"
							alt="Metazombies VR"
							src={shape_svg[0]}
							effect="blur"
							className="rotateme"
						/>
					</div> */}
					<div className="container custom-container">
						<div className="row align-items-center">
							<div className="col-lg-6 order-0 order-lg-2">
								<div className="third-about-img text-right position-relative">
									<div className="video" style={{ borderImage: `url(${bg_line}) 20` }}>
										<div data-type="youtube" data-id="vXxazfbj_to" data-width="800" data-height="300" className="lightbox">
											{/* <img src={backGrounds[2]} alt="" /> */}
											<LazyLoadImage
												loading="lazy"
												alt="Metazombies VR"
												src={backGrounds[2]}
												effect="blur"
											/>
											<span className="play" onClick={() => setlightboxOpen(!lightboxOpen)}>
												<FiPlayCircle size={48} />
											</span>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-6">
								<div className="third-about-content">
									<div className="third-title-style">
										<h2>Game Story</h2>
										<div className="blood" style={{ backgroundImage: `url(${bg_title})` }}></div>
										<div className="inner">
											<p>
												Events in game happen in 2050, after world war 3, humans face a deadly pandemic called “white flu” which damages victims’ health, makes them uncontrolled, causes mutations for body cells, transforms them into bloodthirsty zombies, spreading horror over the world. After the first infection, the pandemic spread quickly and became an obsession for all of humanity, the world was engulfed in destruction and misery. 02 main characters: Tom and Taylor are the last survivors who will start a journey to destroy zombies and bring life back to the planet.
											</p>
										</div>
										<div className="d-flex wrap-btn">
											<a href="https://docsend.com/view/uheuq8mxgeamz3rb" className="btn rotated-btn" target="_blank" rel="noopener noreferrer">Documents</a>
											<a href="" className="btn rotated-btn">Market Place</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section
					className="third-about-bg pt-90 pb-90 characters-wrapper"
					style={{ backgroundImage: `url(${backGrounds[0]})` }}
				>
					<div className="characters-overlay"></div>

					<div className="container custom-container">
						<div className="row align-items-center">
							<div className="col-12">
								<div className="third-title-style text-center">
									<h2>Characters</h2>
									<div className="blood" style={{ backgroundImage: `url(${bg_title})` }}></div>
								</div>
							</div>
							{/* <div className="col-12">
								<Swiper
									pagination={pagination}
									modules={[Pagination]}
									className="characters-slider"
								>
									{
										characters_content.map((item, index) => {
											return (
												<SwiperSlide key={index}>
													<div className="character-wrap">
														<div className="character-left">
															<img alt="" src={item.img} />
														</div>
														<div className="character-right">
															<h4 className="text-uppercase">{item.name}</h4>
															<p>
																{item.content}
															</p>
														</div>
													</div>
												</SwiperSlide>
											)
										})
									}
								</Swiper>
							</div> */}
							<div className="col-12">
								<div className="character-list">
									{
										characters_content.map((item, index) => {
											return (
												<div key={index} className="character-list-item">
													<div className="character-item-wrap" style={{ borderImage: `url(${bg_roadmap}) 20` }}>
														<div className="character-item-left">
															<img alt="" src={item.img} />
														</div>
														<div className="character-item-right">
															<h4 className="text-uppercase">{item.name}</h4>
															<p>
																{item.content}
															</p>
														</div>
													</div>
												</div>
											)
										})
									}
								</div>
							</div>
						</div>
					</div>
				</section>
				<section id="nft" className="third-about-bg nft-wrap pt-90 pb-90">
					{/* <div className="slider-circle-shape nft-circle-shape">
						<img src={shape_svg[3]} alt="" className="rotateme" />
					</div> */}
					<div className="container custom-container">
						<div className="row">
							<div className="col-lg-7 col-md-8 col-12">
								<div className="third-title-style text-left">
									<h2>GUNS</h2>
									<div className="blood" style={{ backgroundImage: `url(${bg_title})` }}></div>
									<p>Guns, Ammunition, and Items in the game will be designed on the BSC platform with top-notch graphics to create the most real feeling for players when participating in the game.</p>
								</div>
							</div>
						</div>
						<div className="row align-items-center">
							<div className="col-12">
								<Swiper
									autoplay={{
										delay: 2500,
										disableOnInteraction: false,
									}}
									className="guns-slider"
									slidesPerView={1}
									spaceBetween={30}
									loop={true}
									modules={[Navigation, Thumbs]}
									thumbs={{ swiper: thumbsSwiper }}
								>
									{
										guns.map((item, index) => {
											return (
												<SwiperSlide key={index} >
													<div className="gun-item latest-games-items mb-30">
														<div className="latest-games-thumb gun-item-slide">
															{/* <div className="shape-top"></div> */}
															{/* <Image
																src={item.nft}
																webp={item.nft_webp}
															/> */}
															<LazyLoadImage
																loading="lazy"
																alt="Metazombies VR"
																src={item.nft_webp}
																effect="blur"
															/>
															<div className="gun-item-slide-name">
																<h4><span>{item.name}</span></h4>
																<p>Class : <span>{item.type}</span></p>
															</div>
															{/* <div className="shape-bottom"></div> */}
														</div>
														<div className="latest-games-content gun-content"
														>
															{/* <div className="shape-top"></div> */}
															<div className="gun-attribute-wrap">
																<div className="gun-attribute">
																	<div className="gun-attribute-left">
																		{/* <img src={item.damage.icon} alt="" />  */}
																		<LazyLoadImage
																			loading="lazy"
																			alt="Metazombies VR"
																			src={item.damage.icon}
																			effect="blur"
																		/>
																	</div>
																	<div className="gun-attribute-right">
																		<h3 className="gun-attribute-name">Damage</h3>
																		<div className="number" style={{ backgroundImage: `url(${percent[1]})` }}>
																			<div className="gun-attribute-progress-wrap" style={{ backgroundImage: `url(${percent[2]})` }}>
																				<div className="gun-attribute-progress" style={{ width: `${item.damage.percent_cal}%`, backgroundImage: `url(${percent[0]})` }}></div>
																			</div>
																			<span className="current">{item.damage.number}</span>
																		</div>
																	</div>
																</div>
																<div className="gun-attribute">
																	<div className="gun-attribute-left">
																		{/* <img src={item.fire_rare.icon} alt="" />  */}
																		<LazyLoadImage
																			loading="lazy"
																			alt="Metazombies VR"
																			src={item.fire_rare.icon}
																			effect="blur"
																		/>
																	</div>
																	<div className="gun-attribute-right">
																		<h3>Fire Rate</h3>
																		<div className="number" style={{ backgroundImage: `url(${percent[1]})` }}>
																			<div className="gun-attribute-progress-wrap" style={{ backgroundImage: `url(${percent[2]})` }}>
																				<div className="gun-attribute-progress" style={{ width: `${item.fire_rare.percent_cal}%`, backgroundImage: `url(${percent[0]})` }}></div>
																			</div>
																			<span className="current">{item.fire_rare.number}</span>
																		</div>
																	</div>
																</div>
																<div className="gun-attribute">
																	<div className="gun-attribute-left">
																		{/* <img src={item.recoil_control.icon} alt="" />  */}
																		<LazyLoadImage
																			loading="lazy"
																			alt="Metazombies VR"
																			src={item.recoil_control.icon}
																			effect="blur"
																		/>
																	</div>
																	<div className="gun-attribute-right">
																		<h3>Recoil Control</h3>
																		<div className="number" style={{ backgroundImage: `url(${percent[1]})` }}>
																			<div className="gun-attribute-progress-wrap" style={{ backgroundImage: `url(${percent[2]})` }}>
																				<div className="gun-attribute-progress" style={{ width: `${item.recoil_control.percent_cal}%`, backgroundImage: `url(${percent[0]})` }}></div>
																			</div>
																			<span className="current">{item.recoil_control.number}</span>
																		</div>
																	</div>
																</div>
																<div className="gun-attribute">
																	<div className="gun-attribute-left">
																		{/* <img src={item.accurate.icon} alt="" />  */}
																		<LazyLoadImage
																			loading="lazy"
																			alt="Metazombies VR"
																			src={item.accurate.icon}
																			effect="blur"
																		/>
																	</div>
																	<div className="gun-attribute-right">
																		<h3>Accurate</h3>
																		<div className="number" style={{ backgroundImage: `url(${percent[1]})` }}>
																			<div className="gun-attribute-progress-wrap" style={{ backgroundImage: `url(${percent[2]})` }}>
																				<div
																					className="gun-attribute-progress"
																					style={{
																						width: `${item.accurate.percent_cal}%`,
																						backgroundImage: `url(${percent[0]})`
																					}}
																				></div>
																			</div>
																			<span className="current">{item.accurate.number}</span>
																		</div>
																	</div>
																</div>
																<div className="gun-attribute">
																	<div className="gun-attribute-left">
																		{/* <img src={item.magazine_size.icon} alt="" /> */}
																		<LazyLoadImage
																			loading="lazy"
																			alt="Metazombies VR"
																			src={item.magazine_size.icon}
																			effect="blur"
																		/>
																	</div>
																	<div className="gun-attribute-right">
																		<h3>Magazine Size</h3>
																		<div className="number" style={{ backgroundImage: `url(${percent[1]})` }}>
																			<div className="gun-attribute-progress-wrap" style={{ backgroundImage: `url(${percent[2]})` }}>
																				<div className="gun-attribute-progress" style={{ width: `${item.magazine_size.percent_cal}%`, backgroundImage: `url(${percent[0]})` }}></div>
																			</div>
																			<span className="current">{item.magazine_size.number}</span>
																		</div>
																	</div>
																</div>
																<div className="gun-attribute">
																	<div className="gun-attribute-left">
																		{/* <img src={item.force.icon} alt="" /> */}
																		<LazyLoadImage
																			loading="lazy"
																			alt="Metazombies VR"
																			src={item.force.icon}
																			effect="blur"
																		/>
																	</div>
																	<div className="gun-attribute-right">
																		<h3>Force</h3>
																		<div className="number" style={{ backgroundImage: `url(${percent[1]})` }}>
																			<div className="gun-attribute-progress-wrap" style={{ backgroundImage: `url(${percent[2]})` }}>
																				<div className="gun-attribute-progress" style={{ width: `${item.force.percent_cal}%`, backgroundImage: `url(${percent[0]})` }}></div>
																			</div>
																			<span className="current">{item.force.number}</span>
																		</div>
																	</div>
																</div>
																<div className="gun-attribute">
																	<div className="gun-attribute-left">
																		{/* <img src={item.range.icon} alt="" /> */}
																		<LazyLoadImage
																			loading="lazy"
																			alt="Metazombies VR"
																			src={item.range.icon}
																			effect="blur"
																		/>
																	</div>
																	<div className="gun-attribute-right">
																		<h3>Range</h3>
																		<div className="number" style={{ backgroundImage: `url(${percent[1]})` }}>
																			<div className="gun-attribute-progress-wrap" style={{ backgroundImage: `url(${percent[2]})` }}>
																				<div className="gun-attribute-progress" style={{ width: `${item.range.percent_cal}%`, backgroundImage: `url(${percent[0]})` }}></div>
																			</div>
																			<span className="current">{item.range.number}</span>
																		</div>
																	</div>
																</div>
															</div>
															{/* <div className="shape-bottom"></div> */}
														</div>
													</div>
												</SwiperSlide>
											)
										})
									}
								</Swiper>
								<Swiper
									onSwiper={setThumbsSwiper}
									spaceBetween={20}
									slidesPerView={5}
									watchSlidesProgress={true}
									modules={[Navigation, Thumbs]}
									className="guns_slider_thumb"
									navigation={true}
									//freeMode={true}
									style={{
										"--swiper-navigation-color": "#fff",
										"--swiper-pagination-color": "#fff",
									}}
									breakpoints={{
										"@0.00": {
											"slidesPerView": 3,
											"spaceBetween": 10
										},
										"@0.75": {
											"slidesPerView": 3,
											"spaceBetween": 20
										},
										"@1.00": {
											"slidesPerView": 3,
											"spaceBetween": 40
										},
										"@1.50": {
											"slidesPerView": 5,
											"spaceBetween": 30
										}
									}}
								>
									{
										guns.map((item, index) => {
											return (
												<SwiperSlide key={index}>
													<div className="latest-games-items">
														{/* <div className="shape-top"></div> */}
														<div className="latest-games-thumb">
															{/* <Image
																src={item.thumb}
																webp={item.thumb_webp}
															/> */}
															<LazyLoadImage
																loading="lazy"
																alt="Metazombies VR"
																src={item.thumb_webp}
																effect="blur"
															/>
														</div>
														<h4 className="thumb-name">{item.name}</h4>
														{/* <div className="shape-bottom"></div> */}
													</div>
												</SwiperSlide>
											)
										})
									}
								</Swiper>
							</div>
						</div>
					</div>
				</section>
				<section id="nft" className="third-about-bg nft-wrap pb-90">
					<div className="container custom-container">
						<div className="row align-items-center">
							<div className="col-12">
								{
									!openVideo ?
									<div className="video-full" style={{ borderImage: `url(${bg_roadmap}) 20` }}>
										<LazyLoadImage
											loading="lazy"
											alt="Metazombies VR"
											src={thumb_video_full}
											effect="blur"
										/>
										<span className="play" onClick={() => setOpenVideo(true)}>
											<FaYoutube size={48} />
										</span>
									</div>

									:

									<div className="video-full" style={{ borderImage: `url(${bg_roadmap}) 20` }}>
										<div className="embed-responsive embed-responsive-16by9">
											<YouTube videoId="wN8CzNUVHL4" opts={opts} />
										</div>
									</div>
								}
							</div>
						</div>
					</div>
				</section>
				<section className="third-about-bg items-wrap pt-90 pb-90" style={{ backgroundImage: `url(${backGrounds[6]})` }}>
					<div className="container custom-container">
						<div className="row pb-40">
							<div className="col-lg-12 col-md-12 col-12">
								<div className="third-title-style text-center">
									<h2>Items</h2>
									<div className="blood" style={{ backgroundImage: `url(${bg_title})` }}></div>
									<p>Collecting valuable items during battles to defeat your enemies.</p>
								</div>
							</div>
						</div>
						<div className="row align-items-center">
							<div className="col-12">
								<div className="items_wrap">
									{
										items.map((item, index) => {
											return (
												<div className="nft-item latest-games-items" key={index}>
													<a className="latest-item-img" data-fancybox="gallery-items" href={item.nft_webp}>
														{/* <Image
															src={item.nft}
															webp={item.nft_webp}
														/> */}
														<LazyLoadImage
															loading="lazy"
															alt="Metazombies VR"
															src={item.nft_webp}
															effect="blur"
														/>
														<h4 className="item-thumb-name">{item.name}</h4>
													</a>
												</div>
											)
										})
									}
								</div>
							</div>
						</div>
					</div>
				</section>
				{/* <section id="gallery" className="third-about-bg pt-90 pb-90">
					<div className="container custom-container">
						<div className="row align-items-center">
							<div className="col-12">
								<div className="third-title-style text-center">
									<h2>Gallery</h2>
									<p>Choose your favorite maps to have new experiences in the game.</p>
								</div>
							</div>
							<div className="col-12">
								<div className="gallery">
									<Fancybox>
										{
											gallery.map((item, index) => {
												return (
													<a className="gallery-item gallery-item-hover" key={index} data-fancybox="gallery" href={item}>
														
														<LazyLoadImage
															loading="lazy"
															alt="Metazombies VR"
															src={item}
															effect="blur"
														/>
													</a>
												)
											})
										}
									</Fancybox>
								</div>
							</div>
						</div>
					</div>
				</section> */}
				<section id="comic" className="comic-home-wrapper pt-90 pb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
							<div className="third-title-style text-center pb-40">
								<h2>Hand-crafted Comic design</h2>
								<div className="blood" style={{ backgroundImage: `url(${bg_title})` }}></div>
								<p>The comic will be regularly updated and NFT owners can enjoy Metazombies VR Comic series.</p>
							</div>
                            <div className="comic-wrap">
                                <HTMLFlipBook
                                    width={600}
                                    height={450}
                                    size="stretch"
                                    minWidth={315}
                                    maxWidth={1000}
                                    minHeight={450}
                                    maxShadowOpacity={0.5}
                                    showCover={false}
                                    mobileScrollSupport={true}
                                    drawShadow={true}
                                    className="demo-book"
                                    ref={flipbook}
                                    flippingTime={750}
                                    useMouseEvents={true}
                                >
                                    {
                                        comic.map((item, index) => {
                                            return (
                                                <Page className="comic-item" key={index} number={index + 1}>
                                                    {/* <img src={item} alt="" /> */}
                                                    <LazyLoadImage
                                                        loading="lazy"
                                                        alt=""
                                                        src={item}
                                                        effect="blur"
                                                    />
                                                </Page>
                                            )
                                        })
                                    }
                                    <PageCover>
                                        <div className="page-comingsoon">
                                            <img src={Logo} alt="Logo" />
                                            <h2>Coming soon ...</h2>
                                        </div>
                                    </PageCover>

                                </HTMLFlipBook>
                                <div className="page-comic-navigation">
                                    <div
                                        className="page-comic-button page-comic-prev"
                                        onClick={flipBack}
                                    >
                                        <MdOutlineArrowBackIos />
                                    </div>
                                    <div
                                        className="page-comic-button page-comic-next"
                                        onClick={flipForward}
                                    >
                                        <MdOutlineArrowForwardIos />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
				<section className="third-about-bg pt-90 pb-90">
					<div className="container custom-container">
						<div className="row align-items-center">
							<div className="col-12 pb-90">
								<div className="third-title-style text-center">
									<h2>WHAT IS METAZOMBIES VR TOKEN?</h2>
									<div className="blood" style={{ backgroundImage: `url(${bg_title})` }}></div>
								</div>
							</div>
							<div className="col-12 col-lg-7">
								<div className="coin-text">
									<span className="d-block">
										MetaZombies ($MZB) is the currency that is released and used in the MetaZombies Ecosystems. $MZB operates on Binance Smart Chain (BSC) Network.
									</span>
									<span className="d-block">$MZB token can be used for:</span>
									<span className="d-block pl-2">1. Buying Zcoin - an important coin in-game for purchasing weapons, equipments, items, etc</span>
									<span className="d-block pl-2">2. Game Rewards: Players will be rewarded based on their TOP Ranking in the game.</span>
									<span className="d-block pl-2">3. Staking: Players can stake $MZB to get rewards</span>
									<span className="d-block pl-2">4. Trading: Exchange on Centralized/Decentralized exchanges.</span>
									<span className="d-block">How to own $MZB?</span>
									<span className="d-block pl-2">1. Buy from PancakeSwap.</span>
									<span className="d-block pl-2">2. From playing game, Ranking regime will reward tokens to best players.</span>
								</div>
							</div>
							<div className="col-lg-5 col-12">
								<div className="coin-img">
									<LazyLoadImage
										loading="lazy"
										alt=""
										src={coin}
										effect="blur"
									/>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section
					id="game-play"
					className="features-wrap third-about-bg pt-90 pb-90"
					style={{ backgroundImage: `url(${backGrounds[2]})` }}
				>
					<div className="slider-circle-shape gameplays-circle-shape">
						{/* <img src={shape_svg[4]} alt="" className="rotateme" /> */}
						<LazyLoadImage
							loading="lazy"
							alt="Metazombies VR"
							src={shape_svg[4]}
							effect="blur"
							className="rotateme"
						/>
					</div>
					<div className="container custom-container">
						<div className="row align-items-center">
							<div className="col-lg-6 order-0 order-lg-2">
								<div className="third-about-img text-right position-relative">

								</div>
							</div>
							<div className="col-lg-8 offset-lg-2 col-12 pb-40">
								<div className="third-title-style text-center">
									<h2>Game <span>play</span></h2>
									<div className="blood" style={{ backgroundImage: `url(${bg_title})` }}></div>
									<p>Various game modes and non-stop battles, build your character and enjoy victorious moments</p>
								</div>
							</div>
							<div className="col-12">
								<div className="features_list">
									<div className="features-games-items">
										<div className="features-games-left">
											<div className="features-games-thumb">
												<LazyLoadImage
													loading="lazy"
													alt="Metazombies VR"
													src={pve}
													effect="blur"
												/>
											</div>
										</div>
										<div className="features-games-right">
											<h4>Large-scale PVE Campaign</h4>
											<p>Players will fight against and eliminate zombies, trying to survive in short missions or take part in the whole series of missions to make achievements on the leaderboard. </p>
										</div>
									</div>
									<div className="features-games-items">
										<div className="features-games-left">
											<div className="features-games-thumb">
												<LazyLoadImage
													loading="lazy"
													alt="Metazombies VR"
													src={coop}
													effect="blur"
												/>
											</div>
										</div>
										<div className="features-games-right">
											<h4>Co-op - Multiplayer</h4>
											<p>Team up with friends for cooperative or antagonistic combats and try to survive waves of increasingly difficult Zombie attacks.</p>
										</div>
									</div>
									<div className="features-games-items">
										<div className="features-games-left">
											<div className="features-games-thumb">
												<LazyLoadImage
													loading="lazy"
													alt="Metazombies VR"
													src={pvp}
													effect="blur"
												/>
											</div>
										</div>
										<div className="features-games-right">
											<h4>PVP ( Update Soon )</h4>
											<p>Players can make a team to fight against other teams to get rewards.</p>
										</div>
									</div>
								</div>
								{/* <Swiper
									spaceBetween={20}
									slidesPerView={1}
									watchSlidesProgress={true}
									modules={[Pagination]}
									className="features_slider_thumb"
									pagination={pagination_features}

								>

									<SwiperSlide>
										<div className="features-games-items">
											<div className="features-games-left">
												<div className="features-games-thumb">
													<LazyLoadImage
														loading="lazy"
														alt="Metazombies VR"
														src={pve}
														effect="blur"
													/>
												</div>
											</div>
											<div className="features-games-right">
												<h4>Large-scale PVE Campaign</h4>
												<p>Players will fight against and eliminate zombies, trying to survive in short missions or take part in the whole series of missions to make achievements on the leaderboard. </p>
											</div>
										</div>
									</SwiperSlide>
									<SwiperSlide>
										<div className="features-games-items">
											<div className="features-games-left">
												<div className="features-games-thumb">
													<LazyLoadImage
														loading="lazy"
														alt="Metazombies VR"
														src={coop}
														effect="blur"
													/>
												</div>
											</div>
											<div className="features-games-right">
												<h4>Co-op - Multiplayer</h4>
												<p>Team up with friends for cooperative or antagonistic combats and try to survive waves of increasingly difficult Zombie attacks.</p>
											</div>
										</div>
									</SwiperSlide>
									<SwiperSlide>
										<div className="features-games-items">
											<div className="features-games-left">
												<div className="features-games-thumb">
													<LazyLoadImage
														loading="lazy"
														alt="Metazombies VR"
														src={pvp}
														effect="blur"
													/>
												</div>
											</div>
											<div className="features-games-right">
												<h4>PVP ( Update Soon )</h4>
												<p>Players can make a team to fight against other teams to get rewards.</p>
											</div>
										</div>
									</SwiperSlide>
								</Swiper> */}
							</div>
						</div>
					</div>
				</section>
				<section className="third-about-bg pt-90 pb-90">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-12 pb-40">
								<div className="third-title-style text-center">
									<h2>BEHIND THE SCENES</h2>
									<div className="blood" style={{ backgroundImage: `url(${bg_title})` }}></div>
								</div>
							</div>
							<div className="col-12">
								<div className="gallery">
									<Fancybox>
										{
											gallery.map((item, index) => {
												return (
													<a className="gallery-item gallery-item-hover" key={index} data-fancybox="gallery" href={item}>
														{/* <img src={item} alt="" /> */}
														<LazyLoadImage
															loading="lazy"
															alt=""
															src={item}
															effect="blur"
														/>
													</a>
												)
											})
										}
									</Fancybox>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section
					id="roadmap"
					className="third-about-bg pt-90 pb-90"
				>
					<div className="container custom-container">
						<div className="row align-items-center">
							<div className="col-12 pb-40">
								<div className="third-title-style text-center">
									<h2>Road <span>map</span></h2>
									<div className="blood" style={{ backgroundImage: `url(${bg_title})` }}></div>
								</div>
							</div>
							<div className="col-12">
								<Swiper
									pagination={{
										clickable: true
									}}
									spaceBetween={20}
									slidesPerView={3}
									watchSlidesProgress={true}
									modules={[Pagination]}
									className="roadmap_slider_thumb"
									breakpoints={{
										"@0.00": {
											"slidesPerView": 1,
											"spaceBetween": 10
										},
										"@0.75": {
											"slidesPerView": 2,
											"spaceBetween": 20
										},
										"@1.00": {
											"slidesPerView": 3,
											"spaceBetween": 40
										},
										"@1.50": {
											"slidesPerView": 3,
											"spaceBetween": 30
										}
									}}

								>
									<SwiperSlide>
										<div className="roadmap-item" style={{ borderImage: `url(${bg_roadmap}) 20` }}>
											<div className="roadmap-head">
												<div className="tag">Q2 2021</div>
												<ul>
													<li className="info">Discuss content, brainstorm ideas</li>
													<li className="info">Refer and examine similar products</li>
													<li className="info">Sort out the best ideas</li>
													<li className="info">Develop and evaluate concepts</li>
													<li className="info">Financial strategy analysis</li>
												</ul>
											</div>
										</div>
									</SwiperSlide>
									<SwiperSlide>
										<div className="roadmap-item" style={{ borderImage: `url(${bg_roadmap}) 20` }}>
											<div className="roadmap-head">
												<div className="tag">Q3-Q4 2021</div>
												<ul>
													<li className="info">Design UI and create in-game contents</li>
													<li className="info">Create a logo for the game</li>
													<li className="info">Create pitch desk</li>
													<li className="info">Launch social media channels</li>
													{/* <li className="info">Exclusive NFT design brainstorming</li> */}
												</ul>
											</div>
										</div>
									</SwiperSlide>
									<SwiperSlide>
										<div className="roadmap-item" style={{ borderImage: `url(${bg_roadmap}) 20` }}>
											<div className="roadmap-head">
												<div className="tag">Q1 2022</div>
												<ul>
													<li className="info">Website launching</li>
													<li className="info">Social & Marketing</li>
													{/* <li className="info">Seeking for partners</li> */}
													<li className="info">Behind the Scenes of Game development</li>
												</ul>
											</div>
										</div>
									</SwiperSlide>
									<SwiperSlide>
										<div className="roadmap-item" style={{ borderImage: `url(${bg_roadmap}) 20` }}>
											<div className="roadmap-head">
												<div className="tag">Q2 2022</div>
												<ul>
													{/* <li className="info">Open strategic and private rounds</li> */}
													{/* <li className="info">Whitelist for the first 2000 NFTs</li> */}
													{/* <li className="info">Start the Whitelist</li> */}
													{/* <li className="info">Open the Public sale</li> */}
													<li className="info">List tokens in Exchanges and add Liquidity for Metazombies Token</li>
													{/* <li className="info">Launch the Demo in Virtual Reality (VR)</li> */}
													<li className="info">Release demo version in Virtual reality (VR)</li>
													<li className="info">Issue Gameplay instructions</li>
													<li className="info">Launch Testnet version.</li>
													{/* <li className="info">Introducing the exclusive benefits of owning NFT: membership can join the community and participate in the game to earn NFTs and Tokens.</li>
													<li className="info">Airdrop Metazombie Tokens to the first 200 NFT holders</li>
													<li className="info">Public Sale of the remaining NFTs</li>
													<li className="info">Creating ideas, drafting MetaZombie comic series (the comic will be regularly updated)</li>
													<li className="info">Launch Testnet</li> */}
												</ul>
											</div>
										</div>
									</SwiperSlide>
									<SwiperSlide>
										<div className="roadmap-item" style={{ borderImage: `url(${bg_roadmap}) 20` }}>
											<div className="roadmap-head">
												<div className="tag">Q3 2022</div>
												<ul>
													<li className="info">Mainnet: Launch the game in Virtual Reality (VR), allow members own membership to join the game</li>
													<li className="info">PVE mode</li>
													<li className="info">Co-op mode</li>
													{/* <li className="info">Listing tokens and Add Liquidity</li> */}
												</ul>
											</div>
										</div>
									</SwiperSlide>
									<SwiperSlide>
										<div className="roadmap-item" style={{ borderImage: `url(${bg_roadmap}) 20` }}>
											<div className="roadmap-head">
												<div className="tag">Q4 2022</div>
												<ul>
													{/* <li className="info">Launch Marketplace</li> */}
													<li className="info">List game’s tokens on CEX</li>
													{/* <li className="info">Publish extra 10,000 NFTs for new users</li> */}
													<li className="info">Update game: expand game’s world, add new modes, weapons, zombies, etc.</li>
													<li className="info">Launch PVP game mode</li>
													<li className="info">PVP ranking</li>
												</ul>
											</div>
										</div>
									</SwiperSlide>
									<SwiperSlide>
										<div className="roadmap-item" style={{ borderImage: `url(${bg_roadmap}) 20` }}>
											<div className="roadmap-head">
												<div className="tag">Q1 2023</div>
												<ul>
													<li className="info">Add new characters</li>
													<li className="info">Organize online, offline tournaments</li>
													<li className="info">Guild Wars feature</li>
												</ul>
											</div>
										</div>
									</SwiperSlide>
									<SwiperSlide>
										<div className="roadmap-item" style={{ borderImage: `url(${bg_roadmap}) 20` }}>
											<div className="roadmap-head">
												<div className="tag">Q2 2023</div>
												<ul>
													{/* <li className="info">Release MetaZombies VR comic</li>
													<li className="info">Adding NFT Lands (ownable area of digital land)</li>
													<li className="info">Organize the 2nd online, offline tournaments</li> */}
													{/* <li className="info">Release Land NFT (possess in-game maps)</li> */}
													{/* <li className="info">Organize the 2nd online, offline tournaments</li> */}
													<li className="info">New Mode</li>
													<li className="info">Organize the 2nd online, offline tournaments</li>
												</ul>
											</div>
										</div>
									</SwiperSlide>
								</Swiper>
							</div>
						</div>
					</div>
				</section>
				{/* <section
					id="teams"
					className="third-about-bg team-wrapper pt-90 pb-90"
					style={{ backgroundImage: `url(${backGrounds[10]})` }}
				>
					<div className="slider-circle-shape team-circle-shape">
						<LazyLoadImage
							loading="lazy"
							alt="Metazombies VR"
							src={shape_svg[2]}
							effect="blur"
							className="rotateme"
						/>
					</div>
					<div className="container custom-container">
						<div className="row align-items-center">
							<div className="col-12 col-lg-8 offset-lg-2">
								<div className="third-title-style text-center">
									<h2>Core <span>Team</span></h2>
									<div className="blood" style={{ backgroundImage: `url(${bg_title})` }}></div>
									<p>Development team always plays an important role in the success of a project. We’ve been working as a team for almost 5 years, and everyone has extensive experience in their respective fields.</p>
								</div>
							</div>
							<div className="col-12">
								<Swiper
									autoplay={{
										delay: 2000,
										disableOnInteraction: false,
									}}
									slidesPerView={5}
									spaceBetween={20}
									pagination={{
										clickable: true
									}}
									modules={[Pagination]}
									className="team-slide"
									breakpoints={{
										"@0.00": {
											"slidesPerView": 1,
											"spaceBetween": 10
										},
										"@0.5": {
											"slidesPerView": 1,
											"spaceBetween": 20
										},
										"@0.75": {
											"slidesPerView": 2,
											"spaceBetween": 20
										},
										"@1.00": {
											"slidesPerView": 3,
											"spaceBetween": 40
										},
										"@1.50": {
											"slidesPerView": 5,
											"spaceBetween": 30
										}
									}}
								>
									{
										teams_core.map((item, index) => {
											return (
												<SwiperSlide key={index}>
													<div className="item-team"
													
													>
														<div className="context">
															<div className="avatar">
																<img src={item.img} alt="" />
															</div>
															<a href={item.linkedin} rel="noreferrer" className="linkedin" target='_blank'>
																<FiLinkedin />
															</a>
															<div className="item-team-text">
																<h4 className="name">{item.name}</h4>
																<span className="position">{item.office}</span>
															</div>
														</div>
													</div>
												</SwiperSlide>
											)
										})
									}
								</Swiper>
							</div>
						</div>
					</div>
				</section> */}
				<section
					className="third-about-bg team-wrapper pt-90 pb-90"
				>
					<div className="container custom-container">
						<div className="row align-items-center">
							<div className="col-12">
								<div className="third-title-style text-center">
									<h2>OUR TECH <span>PARTNER</span></h2>
									<div className="blood" style={{ backgroundImage: `url(${bg_title})` }}></div>
								</div>
							</div>
							<div className="col-12 pb-40">
								<div className="developers">
									{
										tech_partners.map((item, index) => {
											return (
												<div className="developer-item" key={index}>
													<img src={item} alt="" />
												</div>
											)
										})
									}
								</div>
							</div>
							{/* <div className="col-12">
								<div className="third-title-style text-center">
									<h2>DEVELOPER</h2>
									<div className="blood" style={{ backgroundImage: `url(${bg_title})` }}></div>
								</div>
							</div>
							<div className="col-12 mb-3">
								<div className="developers">
									{
										developer.map((item, index) => {
											return (
												<div className="developer-item" key={index}>
													<img src={item} alt="" />
												</div>
											)
										})
									}
								</div>
							</div> */}
							{/* <div className="col-12">
								<div className="third-title-style text-center">
									<h2>Powered by</h2>
								</div>
							</div>
							<div className="col-12">
								<div className="developers">
									<div className="developer-item">
										<img src={logo_mrs} alt="" />
									</div>
								</div>
							</div> */}
						</div>
					</div>
				</section>
			</main>

			{lightboxOpen && (
				<div className="video-lightbox">
					<ReactImageVideoLightbox
						data={[
							{
								url: 'https://www.youtube.com/embed/vXxazfbj_to',
								type: "video",
								title: "Metazombies VR | Gameplay Demo",
							}
						]}
						startIndex={0}
						showResourceCount={true}
						onCloseCallback={() => setlightboxOpen(!lightboxOpen)}
					/>
				</div>
			)}
		</>
	);
}

export default PageGame;
