import React from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import PageTitle from "../../components/Page_Title/Page_Title";
import Socials from "../../components/Socials";
import { backGrounds } from "../../assets/images/index.js";
import { BiJoystick } from "react-icons/bi";
import { SiBlockchaindotcom } from "react-icons/si";
import { FiGlobe, FiUsers } from "react-icons/fi";

import { coin } from "../../assets/images/index.js";

function WorldOfMetazombies() {
    return (
       <>
            <PageTitle 
                title="WORLD OF METAZOMBIES VR" 
                desc="FIND OUT WHY WE ARE THE NEXT GREAT BLOCKCHAIN GAME YOU WILL PLAY!"
                background={backGrounds[4]}
            />
            <section className="about-us-page pt-90 pb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <Tabs className="tabs-vr">
                                <TabList className="tabs-vr-head world-tabs">
                                    <Tab>
                                        <div className="vr-hear-icon">
                                            <FiGlobe />
                                            <span>The world</span>
                                        </div>
                                    </Tab>
                                    <Tab>
                                        <div className="vr-hear-icon">
                                            <BiJoystick />
                                            <span>Gameplay</span>
                                        </div>
                                    </Tab>
                                    <Tab>
                                        <div className="vr-hear-icon">
                                            <FiUsers />
                                            <span>Character</span>
                                        </div>
                                    </Tab>
                                    <Tab>
                                        <div className="vr-hear-icon">
                                            <SiBlockchaindotcom />
                                            <span>Token</span>
                                        </div>
                                    </Tab>
                                </TabList>

                                <TabPanel className="tabs-vr-body">
                                    <div className="world-tab-content">
                                        <div className="world-tab-left">
                                            <h1>THE WORLD</h1>
                                            <span>MetaZombies VR has a meaningful and dark story of the post-pandemic world. You will face difficult challenges, different types of infected zombies, it even will become extremely difficult as you have to kill super zombies (BOSSES). Being the last person on Earth, are you ready for the impossible challenges ahead?</span>
                                        </div>
                                        <div className="world-tab-right">
                                            {/* <img src={backGrounds[1]} alt="" /> */}
                                            <LazyLoadImage
                                                loading="lazy"
                                                alt=""
                                                src={backGrounds[1]}
                                                effect="blur"
                                            /> 
                                        </div>
                                        <div className="world-tab-center">
                                            <div className="world-tab-center-text mb-20">
                                                <span>
                                                The World was destroyed by World War III; a nuclear war happened as the consequence of interest conflict between European countries.
                                                Events in the game occur in the future – the year 2050 - post-apocalypse - after the nuclear war. Human civilization corrupts and the Earth becomes a ruined planet. Moreover, radiation from nuclear explosions causes impacts in the form of a virus.
                                                This is a virus that has never been studied by anyone before: can be transmitted through blood, such as through an open wound, as intruding into the human body, the virus will affect blood vessels, and nerves, causing brain death, cardiac arrest, cell changes; infected people turning into aggressive and bloodthirsty rotting corpses. After the first infection, the epidemic quickly spread uncontrollably.
                                                The nuclear explosion also made the entire surface of the Earth almost uninhabitable, so few humans who survived the catastrophe were forced to go underground, in a subway network, and rebuild human society with a new government, and an elite army to fight against the people infected with the virus as well as to find a cure against the spread of this disease.
                                                The whole world turns into hell, people must struggle for pieces of life.
                                                Humanity is facing one of the greatest catastrophes in human history. This is the Zombie Apocalypse. 2 Main Characters: Tom and Taylor - two of the last survivors in the elite combat team will join the fight, eliminate the Zombie Army and take back life for the planet.
                                                Let's open the World of MetaZombies VR and start the adventure together!
                                                THE LEGENDARY WAR BETWEEN HUMANS AND ZOMBIES HAS OFFICIALLY BEGUN!!!
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel className="tabs-vr-body">
                                    <div className="world-tab-content">
                                        <div className="world-tab-left">
                                            <h1>GAMEPLAY</h1>
                                            <span>If you are getting bored with ordinary games and are looking for a top entertaining game, then MetaZombies VR is suitable for you. MetaZombies VR is a survival shooting game integrated with modern virtual reality technology that promises to bring you the best entertaining hours when participating in the game.</span>
                                        </div>
                                        <div className="world-tab-right">
                                            {/* <img src={backGrounds[0]} alt="" /> */}
                                            <LazyLoadImage
                                                loading="lazy"
                                                alt=""
                                                src={backGrounds[3]}
                                                effect="blur"
                                            /> 
                                        </div>
                                        <div className="world-tab-center">
                                            <div className="world-tab-center-text mb-20">
                                                <span className="d-block mb-2">Zombies spawn themselves depending on the difficulty selected by players. At higher levels, zombies can appear in large numbers with armor-equipped zombies, bosses appear more regularly.</span>
                                                <span className="d-block">Realistic weapon control: Motion control by VR glasses to use a variety of modern weapons. Move forward, reload, aim, and pull the trigger to destroy the Zombie Army.</span>
                                            </div>
                                            <div className="world-tab-center-text mb-20">
                                                <h4>Large-scale PVE Campaign</h4>
                                                <span>Players will fight against and eliminate zombies, trying to survive in short missions or take part in the whole series of missions to make achievements on the leaderboard.</span>
                                            </div>
                                            <div className="world-tab-center-text mb-20">
                                                <h4>Co-op - Multiplayer:</h4>
                                                <span>Team up with friends for cooperative or antagonistic combats and try to survive waves of increasingly difficult Zombie attacks.</span>
                                            </div>
                                            <div className="world-tab-center-text mb-20">
                                                <h4>PVP:</h4>
                                                <span>Players can make a team to fight against other teams to get rewards.</span>
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel className="tabs-vr-body">
                                    <div className="world-tab-content">
                                        <div className="world-tab-left">
                                            <h1>CHARACTER</h1>
                                            <span>Each character or zombie is meticulously crafted and designed by our graphics team, bringing the most realistic experience to gamers.</span>
                                        </div>
                                        <div className="world-tab-right">
                                            {/* <img src={backGrounds[3]} alt="" /> */}
                                            <LazyLoadImage
                                                loading="lazy"
                                                alt=""
                                                src={backGrounds[2]}
                                                effect="blur"
                                            /> 
                                        </div>
                                        <div className="world-tab-center">
                                            <div className="world-tab-center-text mb-20">
                                                <h4>TOM</h4>
                                                <span>
                                                    An elite soldier who was trained for years in DELTA force which is one of Special Mission Units and also the no.1 counterterrorism force of the U.S.
                                                    Troops in this force are physically and mentally trained for years with survival skills under extremely harsh conditions so that they can accomplish their missions.
                                                    Tom has excellently accomplished missions that seem to be almost impossible for normal soldiers: counterterrorism, hostage rescue, direct attack and special reconnaissance, etc. With the ability to use various kinds of weapons competently and special training, Tom can eliminate zombies in a perfect way.
                                                </span>
                                            </div>
                                            <div className="world-tab-center-text mb-20">
                                                <h4>TAYLOR</h4>
                                                <span>An excellent SWAT member who used to have a happy family until her husband and son were attacked and infected by zombies at her house while she was on duty. After the transformation of her beloved ones, the young mother’s heart was filled with hatred for zombies and she decided to fight against them and try to survive in this dark time..</span>
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel className="tabs-vr-body">
                                    <div className="world-tab-content">
                                        <div className="world-tab-left">
                                            <h1>TOKEN</h1>
                                            <span>MetaZombies VR is a DeFi gaming ecosystem with the exception that it incorporates blockchain technology.</span>
                                        </div>
                                        <div className="world-tab-right blockchain-tab-img">
                                            {/* <img src={coin} alt="" /> */}
                                            <LazyLoadImage
                                                loading="lazy"
                                                alt=""
                                                src={coin}
                                                effect="blur"
                                            /> 
                                        </div>
                                        <div className="world-tab-center">
                                            <div className="world-tab-center-text mb-20">
                                                <span>
                                                The main token in the MetaZombies VR ecosystem will be $MZB, which will be issued on the Binance Smart Chain platform. $MZB holders will receive premium benefits in the ecosystem such as admin rights, submitting proposals, voting on initiatives, participating in in-game features, staking, etc. and earn profits by participating in MetaZombies VR activities. $MZB is the sole token that can be used to purchase Zcoin, a money that used to upgrade weapons and trade items in the MetaZombies VR Store to help your characters stronger, as well as to increase profits while playing.
                                                </span>
                                            </div>
                                            <div className="world-tab-center-text mb-20">
                                                <h4 className="text-uppercase">How to own $MZB:</h4>
                                                <ul>
                                                    <li>Buy from Pancakeswap.</li>
                                                    <li>Inviting others to play the game.</li>
                                                    <li>Playing and overcome challenges in the game.</li>
                                                    <li>Staking MZB token.</li>
                                                </ul>
                                            </div>
                                            <div className="world-tab-center-text mb-20">
                                                <h4 className="text-uppercase">MetaZombies VR token ($MZB)</h4>
                                                <ul>
                                                    <li>Token name: <strong>MetaZombies VR token</strong>.</li>
                                                    <li>Ticker: <strong>MZB</strong>.</li>
                                                    <li>Blockchain: <strong>Binance Smart Chain</strong>.</li>
                                                    <li>Total Supply: <strong>500.000.000</strong>.</li>
                                                    <li>Initial Supply : <strong>16.500.000</strong>.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                                
                            </Tabs>
                            <Socials />
                        </div>
                    </div>
                </div>
            </section>
       </>
    );
}

export default WorldOfMetazombies;
