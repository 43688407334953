import React from "react";

import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';

import { ToastProvider, useToasts } from 'react-toast-notifications';

import { FiShoppingBag, FiCreditCard, FiCopy, FiLink2, FiFileText, FiRefreshCcw } from "react-icons/fi";
import { BiJoystick } from "react-icons/bi";

import PageTitle from "../../components/Page_Title/Page_Title";

import { coin } from "../../assets/images/index.js";
import { backGrounds } from "../../assets/images/index.js";

import Wallet from "./Wallet";
import Swap from "./Swap";
import Portfolio from "./Portfolio";
import MyNft from "./MyNft";
import MemberProgram from "./MemberProgram";

const Dashboard = ({activeTab, address}) => {

    const FormWithToasts = () => {
        const { addToast } = useToasts();

    const copyToClipBoard = async copyMe => {
        try {
                await navigator.clipboard.writeText(copyMe);
                addToast('Copied Successfully', {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 2000,
                    placement: 'bottom-left'
                })
            } catch (err) {
                addToast('Failed to copy!', {
                    appearance: 'error',
                    autoDismiss: true,
                    autoDismissTimeout: 2000,
                    placement: 'bottom-left'
                })
            }
        };
        return (
            <FiCopy className="copy" onClick={() => copyToClipBoard("")} />
        )
    };

    return (
        <>
            <PageTitle
                title="Dashboard"
                background={backGrounds[2]}
            />
            <Tab.Container defaultActiveKey={activeTab ? activeTab : "wallet"} >
                <section className="dashboard-page pt-60 pb-90">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="dashboard-wrap">
                                    {/* <div className="dashrboard-cover" style={{ backgroundImage: `url(${backGrounds[1]})` }}></div> */}
                                    <div className="dashboard-container">
                                        <div className="dashboard-sidebar">
                                            <div className="mtz-profile_info">
                                                <div className="mtz-profile_pic">
                                                    <img src={coin} alt="" />
                                                </div>
                                                <div className="mtz-wallet">
                                                    <span>
                                                        {
                                                            address ?
                                                                `${address.slice(0, 6)}...${address.slice(
                                                                    address.length - 4,
                                                                    address.length
                                                                )}`
                                                                :
                                                                ""
                                                        }
                                                    </span>
                                                    <ToastProvider
                                                        placement="bottom-left"
                                                    >
                                                        <FormWithToasts />
                                                    </ToastProvider>
                                                </div>
                                            </div>
                                            <div className="mtz-profile_nav">
                                                <Nav variant="pills" className="fs-profile_nav">
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="accountSetting"><BiJoystick /> <span>Game Setting</span></Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="myNft"><FiShoppingBag /> <span>Your Nft</span></Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="wallet"><FiCreditCard /> <span>Game Wallet</span></Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="memberProgram"><FiLink2 /> <span>Member Program</span></Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="swap"><FiRefreshCcw /> <span>Swap</span></Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="portfolio"><FiFileText /> <span>Portfolio</span></Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                            </div>
                                        </div>
                                        <div className="dashboard-content">
                                            <Tab.Content>
                                                <Tab.Pane eventKey="accountSetting">
                                                    
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="myNft">
                                                    <MyNft />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="memberProgram">
                                                    <MemberProgram
                                                        address={address}
                                                    />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="wallet">
                                                    <Wallet />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="swap">
                                                    <Swap />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="portfolio">
                                                    <Portfolio />
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Tab.Container>
        </>
    );
}

export default Dashboard;
